import { Text } from "@react-three/drei";
import { useRef } from "react";

import { useEffect, useState } from "react";
import stateThemeWalls from "../states/stateThemeWalls";
import * as THREE from "three";
import textDataDE from "../text/entryRoomText.json";
import textDataEN from "../text/entryRoomText_en.json";

import { useSnapshot } from "valtio";
import { stateWallPush, stateInAreaRooms, stateEnglisch } from "../../store";
import { RigidBody, CuboidCollider } from "@react-three/rapier";
import { useMemo } from "react";
import { Instances, Model } from "./ThemeWallInstance";
import FontLoader from "../../Utils/FontLoader";

const themeComponentsStrings = [
  "MuseenDerRegion",
  "Holzarbeit",
  "Klima",
  "Salzabbau",
  "Handelswege",
  "Menschen",
];

const { titelFontEntryRoom, titelSecondFont, themeFont, eingangFont } =
  FontLoader();

titelFontEntryRoom.fontSize = 6.5;

const titelTextPosition = new THREE.Vector3(0.5, 41.2129, 28);
const pushTextPosition = new THREE.Vector3(2, 65.5, 0);

const titelTextRotationInRadians = [
  THREE.MathUtils.degToRad(0),
  THREE.MathUtils.degToRad(90),
  THREE.MathUtils.degToRad(0),
];

const themeTextPosition = new THREE.Vector3(0.5, 50, -46);

const mPointsLength = stateThemeWalls.length;

export default function ThemeWall() {
  const [clones, setClones] = useState([]);
  const snapInAreaRooms = useSnapshot(stateInAreaRooms);

  const clonesData = useMemo(() => {
    // Calculate the rotation for each clone based on its index (x)
    return [...Array(mPointsLength).keys()].map((walls, x) => {
      const {
        x: rotationX,
        y: rotationY,
        z: rotationZ,
      } = stateThemeWalls[x].Rotation;

      const rotationInRadians = [
        THREE.MathUtils.degToRad(rotationX),
        THREE.MathUtils.degToRad(-rotationZ),
        THREE.MathUtils.degToRad(rotationY),
      ];

      return {
        key: x,
        position: [...stateThemeWalls[x].Position],
        rotation: rotationInRadians,
        index: stateThemeWalls[x].Id,
      };
    });
  }, [mPointsLength, stateThemeWalls]);

  useEffect(() => {
    setClones(clonesData);
  }, [clonesData]);

  return (
    <>
      {snapInAreaRooms.inAreaRoomEntryRoom ? (
        
        <Instances >
          {clones.map((clone) => (
            <ThemeWallKon
              key={clone.key}
              index={clone.index}
              scale={1}
              position={clone.position}
              rotation={clone.rotation}
            />
          ))}
        </Instances>
      ) : null}
    </>
  );
}

function ThemeWallKon({ position, rotation, index }) {
  const [titel, setTitel] = useState("");
  const [subtitel, setSubtitel] = useState("");
  const [themeText, setThemeText] = useState("");
  const snapIsEnglisch = useSnapshot(stateEnglisch);
  const wallRef = useRef();

  const wallBodyRef = useRef();

  // const wallMeshes = useMemo(() => ({ themeWall: themeWall.nodes.EntryRoom_Wall,}), [themeWall])
  const wallPushIndex = useSnapshot(stateWallPush);

  useEffect(() => {
    const textCurrentIndex = index.replace("Wall_", "");
    const textData = snapIsEnglisch.isEnglisch ? textDataEN : textDataDE;
    const findObjectById = (id) => {
      return (
        textData.find((item) => {
          const modifiedItemId = item.Id.replace("WallText_", ""); // Modify the id property
          return modifiedItemId === id;
        }) || null
      );
    };

    const matchingText = findObjectById(textCurrentIndex);

    if (matchingText) {
      setTitel(matchingText.Titel);
      setSubtitel(matchingText.Subtitel);
      setThemeText(matchingText.ThemeArrowText);
    }



    
  }, []);

  const [wallDown, setWallDown] = useState(false);
  const [wallBodyToggle, setWallBodyToggle] = useState(false);

  const toggleWallPosition = () => {
    setWallBodyToggle(true);
    // animateWallPosition(wallRef.current.position.clone().add(new THREE.Vector3(0, +60, 0)));
  };

  useEffect(() => {
    if (
      themeComponentsStrings[wallPushIndex.activeThemeIndex] ===
      index.replace("Wall_", "")
    ) {
      setWallBodyToggle(false);
    } else {
      setWallBodyToggle(true);
    }
  }, [wallPushIndex.activeThemeIndex]);

  // useFrame((_, delta) => {
  //   TWEEN.update();
  // });

  return (
    <>
      <Model position={position} rotation={rotation}  >
    
      </Model>
      <group position={position} rotation={rotation}>
        <RigidBody ref={wallBodyRef} type="fixed" colliders={false}>
          {wallBodyToggle ? (
            <CuboidCollider args={[2, 32, 115]} position={[0, 25, 0]} />
          ) : null}
        </RigidBody>
        <Text
          {...titelFontEntryRoom}
          fillOpacity={0.7}
          color={"black"}
          position={titelTextPosition}
          rotation={titelTextRotationInRadians}
        >
          {titel}{" "}
        </Text>
        <Text
          {...eingangFont}
          position={pushTextPosition}
          rotation={titelTextRotationInRadians}
          color={"#1A1A1A"}
        >
          {"\n"}
          {subtitel}
        </Text>
        <Text
          {...themeFont}
          position={themeTextPosition}
          rotation={titelTextRotationInRadians}
          color={"black"}
        >
          {themeText}
        </Text>
      </group>
    </>
  );
}
