import * as THREE from 'three'

export default [
	{
		Id: 'ThemeM_Salzabbau',
		Titel: 'ThemeM_Salzabbau',
		Position: new THREE.Vector3 (-199.395, 0.019455, -64.271),
		Rotation: new THREE.Vector3 (0.0,0.0,90.0),
		Link: "./ImageGalleries/IG_DasSalz_DE/index.html"
	},
	{
		Id: 'ThemeM_MuseenDerRegion',
		Titel: 'ThemeM_MuseenDerRegion',
		Position: new THREE.Vector3 (199.069, -0.0758759, 64.3722),
		Rotation: new THREE.Vector3 (0.0,0.0,-90.0),
		Link: "./ImageGalleries/IG_MDR_DE/index.html"
	},
	{
		Id: 'ThemeM_Menschen',
		Titel: 'ThemeM_Menschen',
		Position: new THREE.Vector3 (44.0396, 0.0, 204.523),
		Rotation: new THREE.Vector3 (0.0,0.0,-150.0),
		Link: "./ImageGalleries/IG_Kulturgeschichte_DE/index.html"
	},
	{
		Id: 'ThemeM_Klima',
		Titel: 'ThemeM_Klima',
		Position: new THREE.Vector3 (-44.2034, 0.019455, -204.483),
		Rotation: new THREE.Vector3 (0.0,0.0,30.0),
		Link: "./ImageGalleries/IG_Oekologie_DE/index.html"
	},
	{
		Id: 'ThemeM_Holzarbeit',
		Titel: 'ThemeM_Holzarbeit',
		Position: new THREE.Vector3 (155.169, 0.01315, -140.429),
		Rotation: new THREE.Vector3 (0.0,0.0,-30.0),
		Link: "./ImageGalleries/IG_Holzarbeit_DE/index.html"
	},
	{
		Id: 'ThemeM_Handelswege',
		Titel: 'ThemeM_Handelswege',
		Position: new THREE.Vector3 (-155.092, 0.019455, 140.448),
		Rotation: new THREE.Vector3 (0.0,0.0,150.0),
		Link: "./ImageGalleries/IG_DerSalzTransport_DE/index.html"
	},
	]