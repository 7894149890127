import { useEffect, useState } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { stateInArea } from "../store";
import { usePlayerPosition } from "./PlayerPositionContext";
import { useSnapshot } from "valtio";


const modelPosition = new THREE.Vector3()

let distanceSquared = 0

let newPlayerInsideArea
let relativePlayerPosition
let inverseRotationMatrix

let distanceX 
let  distanceY
let  distanceZ

function CheckInArea(props) {
  const { squareA, squareB, position, index, setAreaActive,rotation } = props;
  // const thresholdSquared = squareA * squareB;
  const thresholdSquared = squareA * squareA + squareB * squareB;
  const [playerInsideArea, setPlayerInsideArea] = useState();
  const { playerPosition } = usePlayerPosition();
  const snapInArea = useSnapshot(stateInArea)
  const [privateActiveArea, setPrivateActiveArea] = useState();

  
//  useEffect(()=>{
//   if (snapInArea.inAreaIndex === "MGrab" || snapInArea.inAreaIndex === "FGrab" ){
//     stateInArea.inAreaPastIndex = index
//    } else return

//  },[stateInArea.inArea])
// useEffect(()=>{

//   if(privateActiveArea)
//   {
//     stateInArea.inAreaIndex = index;
//     stateInArea.inArea = playerInsideArea;
  
//   }else if(!privateActiveArea){ 
//     stateInArea.inArea = false
//     stateInArea.inAreaIndex = "none"
  
//   }else if(index === "MGrab" ){

//     stateInArea.inAreaPastIndex = "Mgrab"
//   }


// },[privateActiveArea])

  
  useEffect(() => {

    stateInArea.inAreaIndex = index;
    stateInArea.inArea = playerInsideArea;
  
  
   
 if(playerInsideArea){
    setAreaActive(playerInsideArea);
    setPrivateActiveArea(true)


  }
  
  else{setAreaActive(false);setPrivateActiveArea(false)}





  }, [playerInsideArea]);


  // const rotationInRadians = [
  //   THREE.MathUtils.degToRad(rotation[0]),
  //   THREE.MathUtils.degToRad(rotation[1]),
  //   THREE.MathUtils.degToRad(rotation[2]),
  // ];

  const rotationMatrix = new THREE.Matrix4().makeRotationFromEuler(
    new THREE.Euler(
      rotation[0],
      rotation[1],
      rotation[2]
    )
  )

  const rotatedModelPosition = new THREE.Vector3();



  useFrame(() => {
    
    if (playerPosition instanceof THREE.Vector3) {
    // Define the player's position relative to the check area
    relativePlayerPosition = playerPosition.clone().sub(new THREE.Vector3(...position));
  
    // Apply the inverse of the rotation to the relative player position
    inverseRotationMatrix = new THREE.Matrix4().copy(rotationMatrix).invert();
    relativePlayerPosition.applyMatrix4(inverseRotationMatrix);

    // Calculate distances from the center of the check area
    distanceX = Math.abs(relativePlayerPosition.x);
    distanceY = Math.abs(relativePlayerPosition.y);
    distanceZ = Math.abs(relativePlayerPosition.z);

    // Check if player is within the rectangular prism
    newPlayerInsideArea = distanceX < squareB / 2 && distanceY < squareB / 2 && distanceZ < squareA / 2;
  
      if (newPlayerInsideArea !== playerInsideArea) {
        setPlayerInsideArea(newPlayerInsideArea);
      }
    }
  });

  return(<mesh position={position} scale={[squareB, 70, squareA]} rotation={rotation}>
    <boxGeometry attach="geometry" args={[1,1,1]  } />
    <meshStandardMaterial attach="material" color="green" wireframe={true}/>
  </mesh> 
  )
}

export default CheckInArea;
