import "./ThemeMonitor.css";
import { Plane, Text } from "@react-three/drei";
import { Clone, Html } from "@react-three/drei";
import { useEffect, useState, useRef } from "react";
import * as THREE from "three";
import GlbLoader from "../Utils/GlbLoader";
import stateIntroMonitor from "./states/stateIntroMonitor";
import { stateInArea } from "../store";
import CheckInArea from "../Utils/CheckInArea.jsx";
import { useSnapshot } from "valtio";
import { useMemo } from "react";
import { stateSound } from "../store";
import FontLoader from "../Utils/FontLoader";
import { statePlayerPositionS } from "../store";

const mPointsLength = stateIntroMonitor.length;

export default function IntroMonitor() {
  
  const [clones, setClones] = useState([]);

  const clonesData = useMemo(() => {
    // Calculate the rotation for each clone based on its index (x)
    return [...Array(mPointsLength).keys()].map((monitor, x) => {
      const {
        x: rotationX,
        y: rotationY,
        z: rotationZ,
      } = stateIntroMonitor[x].Rotation;
  
      const rotationInRadians = [
        THREE.MathUtils.degToRad(rotationX),
        THREE.MathUtils.degToRad(-rotationZ),
        THREE.MathUtils.degToRad(rotationY),
      ];

      const rotationInRadiansCheck = [
        THREE.MathUtils.degToRad(rotationX),
        THREE.MathUtils.degToRad(-rotationZ -90 ),
        THREE.MathUtils.degToRad(rotationY),
      ];

      const adjustCheckPosition = stateIntroMonitor[x].Position.clone();
      adjustCheckPosition.add(new THREE.Vector3(0, 0, 20).applyEuler(new THREE.Euler(rotationInRadiansCheck[0], rotationInRadiansCheck[1], rotationInRadiansCheck[2])));
  
  
      return {
        key: x,
        position: [...stateIntroMonitor[x].Position],
        rotation: rotationInRadians,
        index: [...stateIntroMonitor[x].Id],
        adjustCheckPosition: adjustCheckPosition
      };
    });
  }, [mPointsLength, stateIntroMonitor]);
  
  useEffect(() => {
    setClones(clonesData);
  }, []);

  return (
    <>
      {clones.map((clone) => (
        <IntroMonitorKon
          key={clone.key}
          scale={1}
          position={clone.position}
          rotation={clone.rotation}
          index={stateIntroMonitor[clone.key].Id}
          adjustCheckPosition={clone.adjustCheckPosition}
        ></IntroMonitorKon>
      ))}
    </>
  );
}

function IntroMonitorKon({ position, rotation, index,adjustCheckPosition }) {
  const modelRef = useRef();
  const { introMonitor, themeInfoMap } = GlbLoader();
  const [iframeActive, setIframeActive] = useState(false);
  const snapPlayerInsideArea = useSnapshot(stateInArea);
  const playerInsideArea = snapPlayerInsideArea.inArea;
  const [areaActive, setAreaActive] = useState(false);
  const [prevAudio, setPrevAudio] = useState(false);
  const [hovered, setHover] = useState();
  const screenPositionStatic = new THREE.Vector3(-7.00777, 4.09774, 0);
  const snapStateSound = useSnapshot(stateSound)
  const snapPlayerPositionS = useSnapshot(statePlayerPositionS)

// Convert Euler angles to quaternion
const euler = new THREE.Euler(
    THREE.MathUtils.degToRad(10),  // Rotation around X-axis
    THREE.MathUtils.degToRad(-90), // Rotation around Y-axis
    THREE.MathUtils.degToRad(-40),   // Rotation around Z-axis
    'ZYX'
  );
  
  const quaternion = new THREE.Quaternion().setFromEuler(euler);
  
  // Convert quaternion back to Euler angles
  const resultingEuler = new THREE.Euler().setFromQuaternion(quaternion);
  
  // Use the resultingEuler for setting rotation

  const {titelSecondFont}  = FontLoader()

  titelSecondFont.fontSize = 1.5


  const handlePlaneClick = () => {
    if (areaActive) {
      setIframeActive(true);
      setPrevAudio(snapStateSound.backAudio)
      stateSound.backAudio = false
    }
  };

  useEffect(() => {
    // Call the callback function with the updated playerInsideArea value
    if (!playerInsideArea) {
      setIframeActive(false);
      if(prevAudio){
      stateSound.backAudio = true
    }
    }

  
  }, [playerInsideArea]);

  const handlePointerOver = () => {
    setHover(true);
  
  };

  const handlePointerOut = () => {
    setHover(false);

  };

  useEffect(() => {
    if(snapPlayerPositionS.isTracking === false)
    {
    document.body.style.cursor = hovered ? "pointer" : "auto";
  }
  }, [hovered]);

  return (
    <>
      <Clone
        scale={1}
        position={position}
        rotation={rotation}
        object={introMonitor.scene}
        ref={modelRef}
      
        onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
      >
        {iframeActive ? (
          <Html
            transform
            wrapperClass="htmlScreen"
            // distanceFactor={1.17}
            distanceFactor={2.45}
            occlude="blending"
            position={screenPositionStatic}
            rotation={resultingEuler}
            zIndexRange={[2, 0]}
            scale={[2, 2, 9]}
         
          >
            <iframe
              src="https://www.youtube.com/embed/YqcC_adZ300?rel=0&autoplay=1&amp&;controls=0"
              title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullScreen
              style={{ userSelect: "none" }}
             
            />
          </Html>
        ) : (
          <Plane
            args={[15, 9,8]}
            position={screenPositionStatic}
            rotation={resultingEuler}
            onClick={handlePlaneClick}
          
          >
            {areaActive ? (
              <Text
                position={[-2, -2.3, 0.5]}
                rotation={[0, 0, 0]}
                color={"white"}
                
                {...titelSecondFont}
               
              >
                {"CLICK"}
               
              </Text>
            ) : null}

            <meshStandardMaterial
              map={themeInfoMap}
              // toneMapped={false}
              //  encoding={SRGBColorSpace}
              roughness={0}
              metalness={0}
              needsUpdate={true}
              toneMapped={false}
             
              // color= {"red"}
            />
          </Plane>
        )}
      </Clone>
      <CheckInArea
        squareA={40}
        squareB={50}
        position={adjustCheckPosition}
        rotation={rotation}
        index={index}
        setAreaActive={setAreaActive}
      />
    </>
  );
}

