import * as THREE from 'three'

export default [


{
	Id: 'CAM_ThemeM_Handelswege',
	Titel: 'CAM_ThemeM_Handelswege',
	Position: new THREE.Vector3 (-138.132, 27.727, 105.185),
	Rotation: new THREE.Vector3 (85.0565,-30.6812,-177.473)
},
{
	Id: 'CAMTARGET_ThemeM_Handelswege',
	Titel: 'CAMTARGET_ThemeM_Handelswege',
	Position: new THREE.Vector3 (-155.489, 25.2301, 134.648),
	Rotation: new THREE.Vector3 (-2.55505e-05,-4.8618e-05,-60.0)
},
{
	Id: 'CAM_ThemeM_Holzarbeit',
	Titel: 'CAM_ThemeM_Holzarbeit',
	Position: new THREE.Vector3 (138.132, 27.727, -105.186),
	Rotation: new THREE.Vector3 (-85.0565,30.6812,2.52712)
},
{
	Id: 'CAMTARGET_ThemeM_Holzarbeit',
	Titel: 'CAMTARGET_ThemeM_Holzarbeit',
	Position: new THREE.Vector3 (155.489, 25.23, -134.648),
	Rotation: new THREE.Vector3 (-1.47878e-06,8.53773e-07,120.0)
},
{
	Id: 'CAM_ThemeM_Klima',
	Titel: 'CAM_ThemeM_Klima',
	Position: new THREE.Vector3 (-22.0275, 27.727, -172.218),
	Rotation: new THREE.Vector3 (-85.1327,-29.1369,-2.37423)
},
{
	Id: 'CAMTARGET_ThemeM_Klima',
	Titel: 'CAMTARGET_ThemeM_Klima',
	Position: new THREE.Vector3 (-38.8638, 25.23, -201.981),
	Rotation: new THREE.Vector3 (-1.47878e-06,-8.53774e-07,60.0)
},
{
	Id: 'CAM_ThemeM_Menschen',
	Titel: 'CAM_ThemeM_Menschen',
	Position: new THREE.Vector3 (22.0275, 27.727, 172.218),
	Rotation: new THREE.Vector3 (85.1327,29.1369,177.626)
},
{
	Id: 'CAMTARGET_ThemeM_Menschen',
	Titel: 'CAMTARGET_ThemeM_Menschen',
	Position: new THREE.Vector3 (38.8638, 25.23, 201.981),
	Rotation: new THREE.Vector3 (1.47878e-06,8.53774e-07,-120.0)
},
{
	Id: 'CAM_ThemeM_MuseenDerRegion',
	Titel: 'CAM_ThemeM_MuseenDerRegion',
	Position: new THREE.Vector3 (160.159, 27.727, 67.0327),
	Rotation: new THREE.Vector3 (10.3152,85.6799,100.344)
},
{
	Id: 'CAMTARGET_ThemeM_MuseenDerRegion',
	Titel: 'CAMTARGET_ThemeM_MuseenDerRegion',
	Position: new THREE.Vector3 (194.353, 25.23, 67.3337),
	Rotation: new THREE.Vector3 (0.0,1.70755e-06,180.0)
},
{
	Id: 'CAM_ThemeM_Salzabbau',
	Titel: 'CAM_ThemeM_Salzabbau',
	Position: new THREE.Vector3 (-160.159, 27.727, -67.0328),
	Rotation: new THREE.Vector3 (-10.3153,-85.6799,-79.656)
},
{
	Id: 'CAMTARGET_ThemeM_Salzabbau',
	Titel: 'CAMTARGET_ThemeM_Salzabbau',
	Position: new THREE.Vector3 (-194.353, 25.23, -67.3337),
	Rotation: new THREE.Vector3 (0.0,-1.70755e-06,0.0)
},
{
	Id: 'CAMTARGET_InfoPoint',
	Titel: 'CAMTARGET_InfoPoint',
	Position: new THREE.Vector3 (10.5502, 29.7254, 14.4386),
	Rotation: new THREE.Vector3 (0.0,-1.46876e-05,-7.5639e-06)
},
{
	Id: 'CAM_InfoPoint',
	Titel: 'CAM_InfoPoint',
	Position: new THREE.Vector3 (10.9132, 76.2301, -113.331),
	Rotation: new THREE.Vector3 (69.9999,-0.152994,-179.944)
},
{
	Id: 'CAM_Landscape',
	Titel: 'CAM_Landscape',
	Position: new THREE.Vector3 (621.381, 84.8287, -9.85199),
	Rotation: new THREE.Vector3 (126.38,88.1975,-143.634)
},
{
	Id: 'CAMTARGET_Landscape',
	Titel: 'CAMTARGET_Landscape',
	Position: new THREE.Vector3 (783.618, 87.8586, -2.42705),
	Rotation: new THREE.Vector3 (0.0,90.0,0.0)
},
//InfoMonitor
{
	Id: 'CAM_InfoPointMonitor003',
	Titel: 'CAM_InfoPointMonitor003',
	Position: new THREE.Vector3 (23.6647, 21.4108, -41.1318),
	Rotation: new THREE.Vector3 (54.8603,-25.0598,-163.415)
},
{
	Id: 'CAMTARGET_InfoPointMonitor003',
	Titel: 'CAMTARGET_InfoPointMonitor003',
	Position: new THREE.Vector3 (16.3357, 12.3634, -28.2942),
	Rotation: new THREE.Vector3 (-26.565,14.4775,116.565)
},
{
	Id: 'CAM_InfoPointMonitor002',
	Titel: 'CAM_InfoPointMonitor002',
	Position: new THREE.Vector3 (-23.7888, 21.4108, -41.0602),
	Rotation: new THREE.Vector3 (54.7299,25.4515,163.078)
},
{
	Id: 'CAMTARGET_InfoPointMonitor002',
	Titel: 'CAMTARGET_InfoPointMonitor002',
	Position: new THREE.Vector3 (-16.3357, 12.3634, -28.2942),
	Rotation: new THREE.Vector3 (-26.5651,-14.4775,63.4349)
},
{
	Id: 'CAM_InfoPointMonitor001',
	Titel: 'CAM_InfoPointMonitor001',
	Position: new THREE.Vector3 (-47.4535, 21.4108, 0.0716248),
	Rotation: new THREE.Vector3 (-0.392333,58.5735,89.5248)
},
{
	Id: 'CAMTARGET_InfoPointMonitor001',
	Titel: 'CAMTARGET_InfoPointMonitor001',
	Position: new THREE.Vector3 (-32.6714, 12.3634, -1.52588e-06),
	Rotation: new THREE.Vector3 (-8.43361e-06,-30.0,-4.81135e-06)
},
{
	Id: 'CAM_InfoPointMonitor006',
	Titel: 'CAM_InfoPointMonitor006',
	Position: new THREE.Vector3 (-23.6647, 21.4108, 41.1318),
	Rotation: new THREE.Vector3 (-54.8603,25.0597,16.5852)
},
{
	Id: 'CAMTARGET_InfoPointMonitor006',
	Titel: 'CAMTARGET_InfoPointMonitor006',
	Position: new THREE.Vector3 (-16.3357, 12.3634, 28.2942),
	Rotation: new THREE.Vector3 (26.5651,-14.4775,-63.435)
},
{
	Id: 'CAM_InfoPointMonitor005',
	Titel: 'CAM_InfoPointMonitor005',
	Position: new THREE.Vector3 (23.7888, 21.4108, 41.0602),
	Rotation: new THREE.Vector3 (-54.7299,-25.4515,-16.9216)
},
{
	Id: 'CAMTARGET_InfoPointMonitor005',
	Titel: 'CAMTARGET_InfoPointMonitor005',
	Position: new THREE.Vector3 (16.3357, 12.3634, 28.2942),
	Rotation: new THREE.Vector3 (26.5651,14.4775,-116.565)
},
{
	Id: 'CAM_InfoPointMonitor004',
	Titel: 'CAM_InfoPointMonitor004',
	Position: new THREE.Vector3 (47.4535, 21.4108, -0.0716431),
	Rotation: new THREE.Vector3 (0.392363,-58.5736,-90.4752)
},
{
	Id: 'CAMTARGET_InfoPointMonitor004',
	Titel: 'CAMTARGET_InfoPointMonitor004',
	Position: new THREE.Vector3 (32.6714, 12.3634, -7.62939e-06),
	Rotation: new THREE.Vector3 (1.56268e-06,30.0,180.0)
},




{
	Id: 'CAM_Salztransport',
	Titel: 'CAM_Salztransport',
	Position: new THREE.Vector3 (-275.0, 48.9742, 470.721),
	Rotation: new THREE.Vector3 (82.3804,-30.4339,-176.123)
},
{
	Id: 'CAMTARGET_Salztransport',
	Titel: 'CAMTARGET_Salztransport',
	Position: new THREE.Vector3 (-347.095, 9.84316, 603.777),
	Rotation: new THREE.Vector3 (0.0,0.0,30.0)
},
// {
// 	Id: 'CAM_Werkzeuge',
// 	Titel: 'CAM_Werkzeuge',
// 	Position: new THREE.Vector3 (-617.353, 47.619, -52.3688),
// 	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
// },
// {
// 	Id: 'CAMTARGET_Werkzeuge',
// 	Titel: 'CAMTARGET_Werkzeuge',
// 	Position: new THREE.Vector3 (-720.349, 1.70498, -45.4735),
// 	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
// },
{
	Id: 'CAM_Gletscherschmelze',
	Titel: 'CAM_Gletscherschmelze',
	Position: new THREE.Vector3 (-265.416, 144.583, -471.757),
	Rotation: new THREE.Vector3 (0.0,0.0,150.0)
},
{
	Id: 'CAMTARGET_Gletscherschmelze',
	Titel: 'CAMTARGET_Gletscherschmelze',
	Position: new THREE.Vector3 (-393.436, 18.8686, -693.494),
	Rotation: new THREE.Vector3 (0.0,0.0,150.0)
},

{
	Id: 'CAM_VideoPoint_NHM',
	Titel: 'Video NHM',
	Position: new THREE.Vector3 (396.93, 22.2042, 218.958),
	Rotation: new THREE.Vector3 (0.0,0.0,150.0)
},
{
	Id: 'CAMTARGET_VideoPoint_NHM',
	Titel: 'Video NHM',
	Position: new THREE.Vector3 (396.681, 15, 229.06),
	Rotation: new THREE.Vector3 (0.0,0.0,150.0)
},

{
	Id: 'CAM_SalineAustriaAG',
	Titel: 'Video NHM',
	Position: new THREE.Vector3 (595, 22.2042, 218.958),
	Rotation: new THREE.Vector3 (0.0,0.0,150.0)
},
{
	Id: 'CAMTARGET_SalineAustriaAG',
	Titel: 'Video NHM',
	Position: new THREE.Vector3 (595, 15, 229.06),
	Rotation: new THREE.Vector3 (0.0,0.0,150.0)
},

{
	Id: 'CAM_VideoPoint_Welterberegion',
	Titel: 'Video NHM',
	Position: new THREE.Vector3 (585, 22.2042, -218.958),
	Rotation: new THREE.Vector3 (0.0,0.0,150.0)
},
{
	Id: 'CAMTARGET_VideoPoint_Welterberegion',
	Titel: 'Video NHM',
	Position: new THREE.Vector3 (585, 15, -229.06),
	Rotation: new THREE.Vector3 (0.0,0.0,150.0)
},

{
	Id: 'CAM_VideoPoint_OOE',
	Titel: 'Video NHM',
	Position: new THREE.Vector3 (413, 22.2042, -218.958),
	Rotation: new THREE.Vector3 (0.0,0.0,150.0)
},
{
	Id: 'CAMTARGET_VideoPoint_OOE',
	Titel: 'Video NHM',
	Position: new THREE.Vector3 (413, 15, -229.06),
	Rotation: new THREE.Vector3 (0.0,0.0,150.0)
},

//SALZABBAU********************************************************************
{
	Id: 'CAM_AnimationSalzEnt',
	Titel: 'CAM_AnimationSalzEnt',
	Position: new THREE.Vector3 (-337.744, 38.09, -165.016),
	Rotation: new THREE.Vector3 (0.0,0.0,38.499)
},
{
	Id: 'CAM_DarstellungBrandgraeber',
	Titel: 'CAM_DarstellungBrandgraeber',
	Position: new THREE.Vector3 (-1461.19, 33.3563, -627.974),
	Rotation: new THREE.Vector3 (-103.545,-89.0047,13.5428)
},
{
	Id: 'CAM_EnglSalzbergtal',
	Titel: 'CAM_EnglSalzbergtal',
	Position: new THREE.Vector3 (-1206.3, 48.7933, -641.5),
	Rotation: new THREE.Vector3 (105.227,89.1125,-164.775)
},
{
	Id: 'CAM_FGrab',
	Titel: 'CAM_FGrab',
	Position: new THREE.Vector3 (-1449.43, 55.5288, -456.759),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAM_FuerstenZimmer',
	Titel: 'CAM_FuerstenZimmer',
	Position: new THREE.Vector3 (-1181.58, 56.3071, 2.70166),
	Rotation: new THREE.Vector3 (9.96283,-85.8986,-99.9879)
},
{
	Id: 'CAM_GeorgRamsauer',
	Titel: 'CAM_GeorgRamsauer',
	Position: new THREE.Vector3 (-1232.53, 54.416, -246.403),
	Rotation: new THREE.Vector3 (88.5595,-0.219817,-179.994)
},
{
	Id: 'CAM_Hallstattstiege',
	Titel: 'CAM_Hallstattstiege',
	Position: new THREE.Vector3 (-592.202, 67.1514, -635.687),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAM_HerzenkammerVideo',
	Titel: 'CAM_HerzenkammerVideo',
	Position: new THREE.Vector3 (-1290.49, 25.1457, -620.895),
	Rotation: new THREE.Vector3 (-55.8809,-0.363924,-0.246569)
},
{
	Id: 'CAM_LebensBildBronzezeit',
	Titel: 'CAM_LebensBildBronzezeit',
	Position: new THREE.Vector3 (-858.144, 58.2222, -649.823),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAM_LebensBildEisenzeit',
	Titel: 'CAM_LebensBildEisenzeit',
	Position: new THREE.Vector3 (-1325.14, 48.4752, -656.498),
	Rotation: new THREE.Vector3 (-90.2331,-0.218141,0.000890873)
},

{
	Id: 'CAM_PicFremdfahrt',
	Titel: 'CAM_PicFremdfahrt',
	Position: new THREE.Vector3 (-412.356, 36.178, 289.983),
	Rotation: new THREE.Vector3 (-16.8024,82.7815,73.071)
},
{
	Id: 'CAM_PicRutsche',
	Titel: 'CAM_PicRutsche',
	Position: new THREE.Vector3 (-409.106, 32.6369, 227.495),
	Rotation: new THREE.Vector3 (20.5408,87.4276,110.56)
},
{
	Id: 'CAM_Position_Artefakt_Ammoniten',
	Titel: 'CAM_Position_Artefakt_Ammoniten',
	Position: new THREE.Vector3 (-416.651, 33.7846, -224.613),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAM_Position_Artefakt_EbenseeSoleleitung',
	Titel: 'CAM_Position_Artefakt_EbenseeSoleleitung',
	Position: new THREE.Vector3 (-1116.29, 47.6854, 74.7384),
	Rotation: new THREE.Vector3 (50.4344,-12.7845,-169.639)
},
{
	Id: 'CAM_Position_Artefakt_Grubenhund',
	Titel: 'CAM_Position_Artefakt_Grubenhund',
	Position: new THREE.Vector3 (-518.581, 46.062, 223.757),
	Rotation: new THREE.Vector3 (59.0902,0.719139,179.569)
},
{
	Id: 'CAM_Position_Artefakt_Salzfabrik',
	Titel: 'CAM_Position_Artefakt_Salzfabrik',
	Position: new THREE.Vector3 (-788.697, 63.5793, 264.677),
	Rotation: new THREE.Vector3 (-0.168421,51.9267,89.7861)
},
{
	Id: 'CAM_Position_Artefakt_Solepfanne',
	Titel: 'CAM_Position_Artefakt_Solepfanne',
	Position: new THREE.Vector3 (-788.697, 63.5793, 386.472),
	Rotation: new THREE.Vector3 (-0.168413,51.9267,89.786)
},
{
	Id: 'CAM_SudhausHallstatt',
	Titel: 'CAM_SudhausHallstatt',
	Position: new THREE.Vector3 (-1267.31, 38.9584, -83.0735),
	Rotation: new THREE.Vector3 (-88.5595,0.0668328,0.0016806)
},
{
	Id: 'CAM_SoleBruecke',
	Titel: 'CAM_SoleBruecke',
	Position: new THREE.Vector3 (-1260.73, 38.7043, 101.654),
	Rotation: new THREE.Vector3 (88.7381,0.0571599,179.999)
},
{
	Id: 'CAM_StollenAnimation',
	Titel: 'CAM_StollenAnimation',
	Position: new THREE.Vector3 (-771.501, 25.1457, -620.895),
	Rotation: new THREE.Vector3 (-55.8817,0.0,0.0)
},
{
	Id: 'CAM_VideoBohrlochsonde',
	Titel: 'CAM_VideoBohrlochsonde',
	Position: new THREE.Vector3 (-853.967, 25.5743, 426.534),
	Rotation: new THREE.Vector3 (56.2,1.55139e-05,180.0)
},
{
	Id: 'CAM_VideoFlugSaline',
	Titel: 'CAM_VideoFlugSaline',
	Position: new THREE.Vector3 (-421.704, 24.9727, 330.453),
	Rotation: new THREE.Vector3 (59.0928,-0.138779,-179.917)
},
{
	Id: 'CAM_VideoImageFilm',
	Titel: 'CAM_VideoImageFilm',
	Position: new THREE.Vector3 (-480.827, 24.7565, 330.35),
	Rotation: new THREE.Vector3 (59.0912,-0.567704,-179.66)
},
{
	Id: 'CAM_KurOrtBadIschl',
	Titel: 'CAM_KurOrtBadIschl',
	Position: new THREE.Vector3 (-592.712, 36.7591, 259.589),
	Rotation: new THREE.Vector3 (90.0928,-0.661662,179.999)
},
{
	Id: 'CAM_VideoNHMForschung',
	Titel: 'CAM_VideoNHMForschung',
	Position: new THREE.Vector3 (-1371.01, 25.1457, -620.895),
	Rotation: new THREE.Vector3 (-55.8793,-0.363917,-0.246579)
},
{
	Id: 'CAM_VideoTeilschnittmaschine',
	Titel: 'CAM_VideoTeilschnittmaschine',
	Position: new THREE.Vector3 (-973.323, 25.2785, 603.174),
	Rotation: new THREE.Vector3 (0.746649,-56.1967,-90.8985)
},

{
	Id: 'CAMTARGET_Salzgewinnung',
	Titel: 'CAMTARGET_Salzgewinnung',
	Position: new THREE.Vector3 (-720.52, 34.2865, 580.772),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAM_Salzgewinnung',
	Titel: 'CAM_Salzgewinnung',
	Position: new THREE.Vector3 (-820.305, 47.6341, 579.104),
	Rotation: new THREE.Vector3 (7.1238,82.3226,97.1876)
},
{
	Id: 'CAM_WallPicBauSohleleitung',
	Titel: 'CAM_WallPicBauSohleleitung',
	Position: new THREE.Vector3 (-1148.85, 39.4324, 68.6012),
	Rotation: new THREE.Vector3 (88.7381,0.0569823,179.999)
},
{
	Id: 'CAM_Werkzeuge',
	Titel: 'CAM_Werkzeuge',
	Position: new THREE.Vector3 (-617.353, 47.619, -52.3688),
	Rotation: new THREE.Vector3 (8.54087,-65.7348,-99.3545)
},
{
	Id: 'CAMTARGET_AnimationSalzEnt',
	Titel: 'CAMTARGET_AnimationSalzEnt',
	Position: new THREE.Vector3 (-294.656, 18.2794, -219.186),
	Rotation: new THREE.Vector3 (-7.04749e-06,-7.15653e-07,-140.798)
},
{
	Id: 'CAMTARGET_DarstellungBrandgraeber',
	Titel: 'CAMTARGET_DarstellungBrandgraeber',
	Position: new THREE.Vector3 (-1515.07, 34.0867, -628.885),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_EnglSalzbergtal',
	Titel: 'CAMTARGET_EnglSalzbergtal',
	Position: new THREE.Vector3 (-1128.33, 49.1105, -640.334),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_FGrab',
	Titel: 'CAMTARGET_FGrab',
	Position: new THREE.Vector3 (-1528.33, 35.8533, -455.381),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_FuerstenZimmer',
	Titel: 'CAMTARGET_FuerstenZimmer',
	Position: new THREE.Vector3 (-1311.03, 47.1647, 4.30756),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_GeorgRamsauer',
	Titel: 'CAMTARGET_GeorgRamsauer',
	Position: new THREE.Vector3 (-1232.9, 51.9851, -149.735),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_Hallstattstiege',
	Titel: 'CAMTARGET_Hallstattstiege',
	Position: new THREE.Vector3 (-579.948, 34.4498, -752.065),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_HerzenkammerVideo',
	Titel: 'CAMTARGET_HerzenkammerVideo',
	Position: new THREE.Vector3 (-1290.56, 18.7118, -630.39),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_LebensBildBronzezeit',
	Titel: 'CAMTARGET_LebensBildBronzezeit',
	Position: new THREE.Vector3 (-856.568, 57.3111, -720.174),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_LebensBildEisenzeit',
	Titel: 'CAMTARGET_LebensBildEisenzeit',
	Position: new THREE.Vector3 (-1325.38, 49.3226, -720.533),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAM_MGrab',
	Titel: 'CAM_MGrab',
	Position: new THREE.Vector3 (-1398.54, 73.0589, -317.932),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_MGrab',
	Titel: 'CAMTARGET_MGrab',
	Position: new THREE.Vector3 (-1526.54, 31.4183, -317.468),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},













{
	Id: 'CAM_Position_Artefakt_Situla',
	Titel: 'CAM_Position_Artefakt_Situla',
	Position: new THREE.Vector3 (-1398.54, 73.0589, -317.932),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_Position_Artefakt_Situla',
	Titel: 'CAMTARGET_Position_Artefakt_Situla',
	Position: new THREE.Vector3 (-1526.54, 31.4183, -317.468),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},

{
	Id: 'CAM_Position_Artefakt_Breitrandschale',
	Titel: 'CAM_Position_Artefakt_Breitrandschale',
	Position: new THREE.Vector3 (-1398.54, 73.0589, -317.932),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_Position_Artefakt_Breitrandschale',
	Titel: 'CAMTARGET_Position_Artefakt_Breitrandschale',
	Position: new THREE.Vector3 (-1526.54, 31.4183, -317.468),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},

{
	Id: 'CAM_Position_Artefakt_EisenbeilHolzstueck',
	Titel: 'CAM_Position_Artefakt_EisenbeilHolzstueck',
	Position: new THREE.Vector3 (-1398.54, 73.0589, -317.932),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_Position_Artefakt_EisenbeilHolzstueck',
	Titel: 'CAMTARGET_Position_Artefakt_EisenbeilHolzstueck',
	Position: new THREE.Vector3 (-1526.54, 31.4183, -317.468),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},

{
	Id: 'CAM_Position_Artefakt_Mehrkopfnadel',
	Titel: 'CAM_Position_Artefakt_Mehrkopfnadel',
	Position: new THREE.Vector3 (-1398.54, 73.0589, -317.932),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_Position_Artefakt_Mehrkopfnadel',
	Titel: 'CAMTARGET_Position_Artefakt_Mehrkopfnadel',
	Position: new THREE.Vector3 (-1526.54, 31.4183, -317.468),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},

{
	Id: 'CAM_Position_Artefakt_EiserneSpeerspitze',
	Titel: 'CAM_Position_Artefakt_EiserneSpeerspitze',
	Position: new THREE.Vector3 (-1398.54, 73.0589, -317.932),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_Position_Artefakt_EiserneSpeerspitze',
	Titel: 'CAMTARGET_Position_Artefakt_EiserneSpeerspitze',
	Position: new THREE.Vector3 (-1526.54, 31.4183, -317.468),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},

{
	Id: 'CAM_Position_Artefakt_Lanzenspitze',
	Titel: 'CAM_Position_Artefakt_Lanzenspitze',
	Position: new THREE.Vector3 (-1398.54, 73.0589, -317.932),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_Position_Artefakt_Lanzenspitze',
	Titel: 'CAMTARGET_Position_Artefakt_Lanzenspitze',
	Position: new THREE.Vector3 (-1526.54, 31.4183, -317.468),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},

{
	Id: 'CAM_Position_Artefakt_Tuellenbeil',
	Titel: 'CAM_Position_Artefakt_Tuellenbeil',
	Position: new THREE.Vector3 (-1398.54, 73.0589, -317.932),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_Position_Artefakt_Tuellenbeil',
	Titel: 'CAMTARGET_Position_Artefakt_Tuellenbeil',
	Position: new THREE.Vector3 (-1526.54, 31.4183, -317.468),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},

{
	Id: 'CAM_Position_Artefakt_Tuellenbeil',
	Titel: 'CAM_Position_Artefakt_Tuellenbeil',
	Position: new THREE.Vector3 (-1398.54, 73.0589, -317.932),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_Position_Artefakt_EisenSchwert',
	Titel: 'CAMTARGET_Position_Artefakt_EisenSchwert',
	Position: new THREE.Vector3 (-1526.54, 31.4183, -317.468),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},



{
	Id: 'CAM_Position_Artefakt_Brillenfibel',
	Titel: 'CAM_Position_Artefakt_Brillenfibel',
	Position: new THREE.Vector3 (-1449.43, 55.5288, -456.759),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},

{
	Id: 'CAMTARGET_Position_Artefakt_Brillenfibel',
	Titel: 'CAMTARGET_Position_Artefakt_Brillenfibel',
	Position: new THREE.Vector3 (-1528.33, 35.8533, -455.381),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},

{
	Id: 'CAM_Position_Artefakt_BronzeArmring',
	Titel: 'CAM_Position_Artefakt_BronzeArmring',
	Position: new THREE.Vector3 (-1449.43, 55.5288, -456.759),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},

{
	Id: 'CAMTARGET_Position_Artefakt_BronzeArmring',
	Titel: 'CAMTARGET_Position_Artefakt_BronzeArmring',
	Position: new THREE.Vector3 (-1528.33, 35.8533, -455.381),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},













{
	Id: 'CAMTARGET_PicFremdfahrt',
	Titel: 'CAMTARGET_PicFremdfahrt',
	Position: new THREE.Vector3 (-370.431, 31.0945, 288.448),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_PicRutsche',
	Titel: 'CAMTARGET_PicRutsche',
	Position: new THREE.Vector3 (-370.688, 31.0206, 228.101),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_Position_Artefakt_Ammoniten',
	Titel: 'CAMTARGET_Position_Artefakt_Ammoniten',
	Position: new THREE.Vector3 (-426.802, 12.5405, -358.678),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_Position_Artefakt_EbenseeSoleleitung',
	Titel: 'CAMTARGET_Position_Artefakt_EbenseeSoleleitung',
	Position: new THREE.Vector3 (-1129.18, 11.5022, 118.53),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_Position_Artefakt_Grubenhund',
	Titel: 'CAMTARGET_Position_Artefakt_Grubenhund',
	Position: new THREE.Vector3 (-517.733, 11.3514, 281.732),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_Position_Artefakt_Salzfabrik',
	Titel: 'CAMTARGET_Position_Artefakt_Salzfabrik',
	Position: new THREE.Vector3 (-722.024, 11.3514, 264.524),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_Position_Artefakt_Solepfanne',
	Titel: 'CAMTARGET_Position_Artefakt_Solepfanne',
	Position: new THREE.Vector3 (-722.024, 11.3514, 386.318),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_SudhausHallstatt',
	Titel: 'CAMTARGET_SudhausHallstatt',
	Position: new THREE.Vector3 (-1267.26, 37.6386, -127.848),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_SoleBruecke',
	Titel: 'CAMTARGET_SoleBruecke',
	Position: new THREE.Vector3 (-1260.68, 37.6177, 150.987),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_StollenAnimation',
	Titel: 'CAMTARGET_StollenAnimation',
	Position: new THREE.Vector3 (-771.573, 18.7118, -630.39),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_VideoBohrlochsonde',
	Titel: 'CAMTARGET_VideoBohrlochsonde',
	Position: new THREE.Vector3 (-853.967, 18.699, 436.805),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_VideoFlugSaline',
	Titel: 'CAMTARGET_VideoFlugSaline',
	Position: new THREE.Vector3 (-421.739, 17.5585, 342.838),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_VideoImageFilm',
	Titel: 'CAMTARGET_VideoImageFilm',
	Position: new THREE.Vector3 (-480.97, 17.3423, 342.734),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_KurOrtBadIschl',
	Titel: 'CAMTARGET_KurOrtBadIschl',
	Position: new THREE.Vector3 (-593.726, 36.9014, 347.428),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_VideoNHMForschung',
	Titel: 'CAMTARGET_VideoNHMForschung',
	Position: new THREE.Vector3 (-1371.09, 18.7118, -630.39),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_VideoTeilschnittmaschine',
	Titel: 'CAMTARGET_VideoTeilschnittmaschine',
	Position: new THREE.Vector3 (-983.593, 18.4032, 603.263),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_WallPicBauSohleleitung',
	Titel: 'CAMTARGET_WallPicBauSohleleitung',
	Position: new THREE.Vector3 (-1148.77, 37.6177, 150.987),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_Werkzeuge',
	Titel: 'CAMTARGET_Werkzeuge',
	Position: new THREE.Vector3 (-720.349, 1.70498, -45.4735),
	Rotation: new THREE.Vector3 (5.00896e-06,5.00896e-06,-8.65142e-06)
},


// HANDELSWEGE *****************************************************




{
	Id: 'CAM_TraunfallPic',
	Titel: 'CAM_TraunfallPic',
	Position: new THREE.Vector3 (-708.095, 52.367, -29.9132),
	Rotation: new THREE.Vector3 (-52.7092,28.4443,19.9371)
},
{
	Id: 'CAMTARGET_TraunfallPic',
	Titel: 'CAMTARGET_TraunfallPic',
	Position: new THREE.Vector3 (-660.93, 45.3157, -105.749),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},


	{
		Id: 'CAMTARGET_Bahntrasport',
		Titel: 'CAMTARGET_Bahntrasport',
		Position: new THREE.Vector3 (226.945, 18.4255, 412.809),
		Rotation: new THREE.Vector3 (0.0,0.0,0.0)
	},
	{
		Id: 'CAM_Bahntrasport',
		Titel: 'CAM_Bahntrasport',
		Position: new THREE.Vector3 (221.515, 26.3829, 421.9),
		Rotation: new THREE.Vector3 (-48.8023,24.2024,19.7413)
	},
	{
		Id: 'CAM_DampfzugHistorisch',
		Titel: 'CAM_DampfzugHistorisch',
		Position: new THREE.Vector3 (131.339, 25.0147, 573.626),
		Rotation: new THREE.Vector3 (54.5222,-25.4647,-162.963)
	},
	{
		Id: 'CAMTARGET_DampfzugHistorisch',
		Titel: 'CAMTARGET_DampfzugHistorisch',
		Position: new THREE.Vector3 (126.317, 18.8941, 582.214),
		Rotation: new THREE.Vector3 (0.0,0.0,0.0)
	},
	{
		Id: 'CAMTARGET_FlugaufnahmeSolebruecke',
		Titel: 'CAMTARGET_FlugaufnahmeSolebruecke',
		Position: new THREE.Vector3 (-901.741, 18.8384, 304.528),
		Rotation: new THREE.Vector3 (0.0,0.0,0.0)
	},
	{
		Id: 'CAM_FlugaufnahmeSolebruecke',
		Titel: 'CAM_FlugaufnahmeSolebruecke',
		Position: new THREE.Vector3 (-893.179, 25.136, 309.291),
		Rotation: new THREE.Vector3 (-36.6357,-47.0083,-44.5269)
	},
	{
		Id: 'CAMTARGET_VideoDerSchiffsTransport',
		Titel: 'CAMTARGET_VideoDerSchiffsTransport',
		Position: new THREE.Vector3 (-862.701, 18.0971, -208.66),
		Rotation: new THREE.Vector3 (0.0,0.0,0.0)
	},
	{
		Id: 'CAM_VideoDerSchiffsTransport',
		Titel: 'CAM_VideoDerSchiffsTransport',
		Position: new THREE.Vector3 (-868.804, 26.0175, -198.194),
		Rotation: new THREE.Vector3 (-52.8832,24.9375,17.6964)
	},
	{
		Id: 'CAM_Position_Artefakt_PferdeeisenbahnTransportgespann',
		Titel: 'CAM_Position_Artefakt_PferdeeisenbahnTransportgespann',
		Position: new THREE.Vector3 (-16.3183, 44.1671, 483.717),
		Rotation: new THREE.Vector3 (-16.1739,64.9699,72.2504)
	},
	{
		Id: 'CAMTARGET_Position_Artefakt_PferdeeisenbahnTransportgespann',
		Titel: 'CAMTARGET_Position_Artefakt_PferdeeisenbahnTransportgespann',
		Position: new THREE.Vector3 (67.8072, 6.43964, 472.775),
		Rotation: new THREE.Vector3 (0.0,0.0,0.0)
	},
	{
		Id: 'CAM_Position_Artefakt_PferdeeisenbahnReisegespann',
		Titel: 'CAM_Position_Artefakt_PferdeeisenbahnReisegespann',
		Position: new THREE.Vector3 (47.0729, 33.0265, 403.936),
		Rotation: new THREE.Vector3 (-28.4008,64.6877,59.1146)
	},
	{
		Id: 'CAMTARGET_Position_Artefakt_PferdeeisenbahnReisegespann',
		Titel: 'CAMTARGET_Position_Artefakt_PferdeeisenbahnReisegespann',
		Position: new THREE.Vector3 (109.327, 7.12645, 389.931),
		Rotation: new THREE.Vector3 (0.0,0.0,0.0)
	},
	{
		Id: 'CAM_Position_Artefakt_GmundenSohleleitung',
		Titel: 'CAM_Position_Artefakt_GmundenSohleleitung',
		Position: new THREE.Vector3 (-803.48, 64.3076, 264.861),
		Rotation: new THREE.Vector3 (-48.601,0.572779,0.504933)
	},
	{
		Id: 'CAMTARGET_Position_Artefakt_GmundenSohleleitung',
		Titel: 'CAMTARGET_Position_Artefakt_GmundenSohleleitung',
		Position: new THREE.Vector3 (-802.647, 9.20811, 202.361),
		Rotation: new THREE.Vector3 (0.0,0.0,0.0)
	},
	{
		Id: 'CAM_Position_Artefakt_Zille',
		Titel: 'CAM_Position_Artefakt_Zille',
		Position: new THREE.Vector3 (-515.605, 46.2719, 218.711),
		Rotation: new THREE.Vector3 (35.3577,-52.2731,-131.895)
	},
	{
		Id: 'CAMTARGET_Position_Artefakt_Zille',
		Titel: 'CAMTARGET_Position_Artefakt_Zille',
		Position: new THREE.Vector3 (-593.195, -4.363, 253.443),
		Rotation: new THREE.Vector3 (0.0,0.0,0.0)
	},
	{
		Id: 'CAM_Position_Artefakt_Kaiserschiff',
		Titel: 'CAM_Position_Artefakt_Kaiserschiff',
		Position: new THREE.Vector3 (-538.138, 80.1293, 196.212),
		Rotation: new THREE.Vector3 (-59.9431,-4.88931,-2.8236)
	},
	{
		Id: 'CAMTARGET_Position_Artefakt_Kaiserschiff',
		Titel: 'CAMTARGET_Position_Artefakt_Kaiserschiff',
		Position: new THREE.Vector3 (-550.708, 6.53437, 69.0338),
		Rotation: new THREE.Vector3 (0.0,0.0,0.0)
	},
	{
		Id: 'CAM_Position_Artefakt_Trauner',
		Titel: 'CAM_Position_Artefakt_Trauner',
		Position: new THREE.Vector3 (-823.065, 45.7043, 59.0025),
		Rotation: new THREE.Vector3 (-56.1953,-16.7627,-10.9296)
	},
	{
		Id: 'CAMTARGET_Position_Artefakt_Trauner',
		Titel: 'CAMTARGET_Position_Artefakt_Trauner',
		Position: new THREE.Vector3 (-845.934, 1.8953, -4.07974),
		Rotation: new THREE.Vector3 (0.0,0.0,0.0)
	},
	{
		Id: 'CAM_Position_Artefakt_Floss',
		Titel: 'CAM_Position_Artefakt_Floss',
		Position: new THREE.Vector3 (-822.583, 49.8767, -101.044),
		Rotation: new THREE.Vector3 (-52.7092,28.4443,19.9371)
	},
	{
		Id: 'CAMTARGET_Position_Artefakt_Floss',
		Titel: 'CAMTARGET_Position_Artefakt_Floss',
		Position: new THREE.Vector3 (-791.464, 16.4269, -146.748),
		Rotation: new THREE.Vector3 (0.0,0.0,0.0)
	},
	{
		Id: 'CAM_Position_Artefakt_Traunfall',
		Titel: 'CAM_Position_Artefakt_Traunfall',
		Position: new THREE.Vector3 (-1003.3, 69.9867, 71.2944),
		Rotation: new THREE.Vector3 (52.9428,-27.8686,-160.558)
	},
	{
		Id: 'CAMTARGET_Position_Artefakt_Traunfall',
		Titel: 'CAMTARGET_Position_Artefakt_Traunfall',
		Position: new THREE.Vector3 (-1044.86, 22.6299, 134.009),
		Rotation: new THREE.Vector3 (0.0,0.0,0.0)
	},
	{
		Id: 'CAMTARGET_SchiffsWerkstatt',
		Titel: 'CAMTARGET_SchiffsWerkstatt',
		Position: new THREE.Vector3 (-965.374, 37.584, -194.48),
		Rotation: new THREE.Vector3 (0.0,0.0,0.0)
	},
	{
		Id: 'CAM_SchiffsWerkstatt',
		Titel: 'CAM_SchiffsWerkstatt',
		Position: new THREE.Vector3 (-918.053, 38.5786, -165.217),
		Rotation: new THREE.Vector3 (-88.0532,-58.2533,-1.65574)
	},
	{
		Id: 'CAM_VotivbildSchiffsleutm',
		Titel: 'CAM_VotivbildSchiffsleutm',
		Position: new THREE.Vector3 (-972.999, 44.3448, -65.3269),
		Rotation: new THREE.Vector3 (-88.0531,-58.2531,-1.65575)
	},
	{
		Id: 'CAMTARGET_VotivbildSchiffsleutm',
		Titel: 'CAMTARGET_VotivbildSchiffsleutm',
		Position: new THREE.Vector3 (-1026.77, 43.2145, -98.5797),
		Rotation: new THREE.Vector3 (0.0,0.0,0.0)
	},
	{
		Id: 'CAMTARGET_Schiffszieher',
		Titel: 'CAMTARGET_Schiffszieher',
		Position: new THREE.Vector3 (-1084.44, 43.2145, 0.821448),
		Rotation: new THREE.Vector3 (0.0,0.0,0.0)
	},
	{
		Id: 'CAM_Schiffszieher',
		Titel: 'CAM_Schiffszieher',
		Position: new THREE.Vector3 (-1030.67, 44.3448, 34.0742),
		Rotation: new THREE.Vector3 (-88.0531,-58.2531,-1.65575)
	},
	{
		Id: 'CAMTARGET_SoleleitungSMA',
		Titel: 'CAMTARGET_SoleleitungSMA',
		Position: new THREE.Vector3 (-927.09, 48.3118, 176.157),
		Rotation: new THREE.Vector3 (0.0,0.0,0.0)
	},
	{
		Id: 'CAM_SoleleitungSMA',
		Titel: 'CAM_SoleleitungSMA',
		Position: new THREE.Vector3 (-848.655, 50.9291, 223.578),
		Rotation: new THREE.Vector3 (-86.8408,-58.8049,-2.70315)
	},
	{
		Id: 'CAMTARGET_SalinenKanal',
		Titel: 'CAMTARGET_SalinenKanal',
		Position: new THREE.Vector3 (-1015.52, 48.3118, 320.868),
		Rotation: new THREE.Vector3 (0.0,0.0,0.0)
	},
	{
		Id: 'CAM_SalinenKanal',
		Titel: 'CAM_SalinenKanal',
		Position: new THREE.Vector3 (-943.558, 50.713, 364.373),
		Rotation: new THREE.Vector3 (-86.8408,-58.8049,-2.70314)
	},
	{
		Id: 'CAM_GmundenWinter',
		Titel: 'CAM_GmundenWinter',
		Position: new THREE.Vector3 (-842.655, 54.2915, 319.378),
		Rotation: new THREE.Vector3 (82.1413,58.281,173.303)
	},
	{
		Id: 'CAMTARGET_GmundenWinter',
		Titel: 'CAMTARGET_GmundenWinter',
		Position: new THREE.Vector3 (-770.131, 48.1625, 363.782),
		Rotation: new THREE.Vector3 (0.0,0.0,0.0)
	},
	

	///// MENSCHEN


	{
		Id: 'CAMTARGET_VideoPoint_ZeitzeugeSalomon',
		Titel: 'CAMTARGET_VideoPoint_ZeitzeugeSalomon',
		Position: new THREE.Vector3 (791.187, 18.238, 145.141),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_VideoPoint_Zeitzeuge',
		Titel: 'CAMTARGET_VideoPoint_Zeitzeuge',
		Position: new THREE.Vector3 (755.967, 18.3779, 85.2171),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_VideoPoint_PaniKucera',
		Titel: 'CAMTARGET_VideoPoint_PaniKucera',
		Position: new THREE.Vector3 (-49.7482, 18.0928, 540.376),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_VideoPoint_HallstaetterVolksfest',
		Titel: 'CAMTARGET_VideoPoint_HallstaetterVolksfest',
		Position: new THREE.Vector3 (-48.8224, 17.8054, 844.773),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_VideoPoint_Habsburger',
		Titel: 'CAMTARGET_VideoPoint_Habsburger',
		Position: new THREE.Vector3 (667.515, 17.5687, 599.771),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_VideoPoint_GloecklerFilm',
		Titel: 'CAMTARGET_VideoPoint_GloecklerFilm',
		Position: new THREE.Vector3 (-91.3604, 17.6293, 827.595),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_VideoPoint_Gedenkstaette',
		Titel: 'CAMTARGET_VideoPoint_Gedenkstaette',
		Position: new THREE.Vector3 (721.24, 17.9782, 23.8119),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_VideoPoint_DieLandler',
		Titel: 'CAMTARGET_VideoPoint_DieLandler',
		Position: new THREE.Vector3 (362.219, 18.5733, 982.137),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_VideoPoint_BrauchtumsMasken',
		Titel: 'CAMTARGET_VideoPoint_BrauchtumsMasken',
		Position: new THREE.Vector3 (-48.9306, 18.5284, 890.03),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_VideoPoint_AusseerFasching',
		Titel: 'CAMTARGET_VideoPoint_AusseerFasching',
		Position: new THREE.Vector3 (8.46851, 17.3739, 811.15),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicVorstaende',
		Titel: 'CAMTARGET_MenschenPicVorstaende',
		Position: new THREE.Vector3 (-260.559, 34.6075, 677.126),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicTrageweiber',
		Titel: 'CAMTARGET_MenschenPicTrageweiber',
		Position: new THREE.Vector3 (-300.051, 37.0033, 615.735),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicSalzSchiffFahne',
		Titel: 'CAMTARGET_MenschenPicSalzSchiffFahne',
		Position: new THREE.Vector3 (703.85, 42.2783, 802.135),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicSalzamtTafel',
		Titel: 'CAMTARGET_MenschenPicSalzamtTafel',
		Position: new THREE.Vector3 (669.471, 31.3008, 303.755),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicReproFasching',
		Titel: 'CAMTARGET_MenschenPicReproFasching',
		Position: new THREE.Vector3 (-37.0615, 36.2275, 686.215),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicPferd',
		Titel: 'CAMTARGET_MenschenPicPferd',
		Position: new THREE.Vector3 (573.387, 31.3008, 358.897),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicMessen',
		Titel: 'CAMTARGET_MenschenPicMessen',
		Position: new THREE.Vector3 (-49.409, 37.9088, 625.794),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicMaria',
		Titel: 'CAMTARGET_MenschenPicMaria',
		Position: new THREE.Vector3 (802.777, 31.3008, 375.659),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicLandler',
		Titel: 'CAMTARGET_MenschenPicLandler',
		Position: new THREE.Vector3 (520.17, 42.0345, 1076.91),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicKZ_F',
		Titel: 'CAMTARGET_MenschenPicKZ_F',
		Position: new THREE.Vector3 (550.367, 26.189, 163.396),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicKZ_E',
		Titel: 'CAMTARGET_MenschenPicKZ_E',
		Position: new THREE.Vector3 (600.361, 26.189, 135.319),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicKZ_D',
		Titel: 'CAMTARGET_MenschenPicKZ_D',
		Position: new THREE.Vector3 (639.755, 26.189, 146.889),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicKZ_C',
		Titel: 'CAMTARGET_MenschenPicKZ_C',
		Position: new THREE.Vector3 (623.206, 26.3464, 187.439),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicKZ_B',
		Titel: 'CAMTARGET_MenschenPicKZ_B',
		Position: new THREE.Vector3 (579.777, 26.3464, 215.849),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicKZ_A',
		Titel: 'CAMTARGET_MenschenPicKZ_A',
		Position: new THREE.Vector3 (537.775, 25.925, 202.148),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicKinderC',
		Titel: 'CAMTARGET_MenschenPicKinderC',
		Position: new THREE.Vector3 (-304.345, 30.5341, 547.518),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicKinderB',
		Titel: 'CAMTARGET_MenschenPicKinderB',
		Position: new THREE.Vector3 (-249.971, 30.5341, 517.143),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicKinderA',
		Titel: 'CAMTARGET_MenschenPicKinderA',
		Position: new THREE.Vector3 (-202.034, 37.3829, 492.152),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicKarl',
		Titel: 'CAMTARGET_MenschenPicKarl',
		Position: new THREE.Vector3 (755.516, 31.3008, 293.981),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicJosef',
		Titel: 'CAMTARGET_MenschenPicJosef',
		Position: new THREE.Vector3 (826.113, 31.3008, 416.689),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicHoschzeit',
		Titel: 'CAMTARGET_MenschenPicHoschzeit',
		Position: new THREE.Vector3 (735.722, 25.8019, 421.514),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicHerztraeger',
		Titel: 'CAMTARGET_MenschenPicHerztraeger',
		Position: new THREE.Vector3 (-115.235, 41.6611, 441.797),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicGoldHaubenFrau',
		Titel: 'CAMTARGET_MenschenPicGoldHaubenFrau',
		Position: new THREE.Vector3 (-0.710938, 39.873, 963.893),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicFranzOld',
		Titel: 'CAMTARGET_MenschenPicFranzOld',
		Position: new THREE.Vector3 (801.184, 31.3008, 525.371),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicFranz',
		Titel: 'CAMTARGET_MenschenPicFranz',
		Position: new THREE.Vector3 (851.657, 31.3008, 458.951),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicFasching',
		Titel: 'CAMTARGET_MenschenPicFasching',
		Position: new THREE.Vector3 (43.6533, 31.9304, 642.9),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicEbensee',
		Titel: 'CAMTARGET_MenschenPicEbensee',
		Position: new THREE.Vector3 (-160.783, 39.031, 755.654),
		Rotation: new THREE.Vector3 (180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicDepoWege',
		Titel: 'CAMTARGET_MenschenPicDepoWege',
		Position: new THREE.Vector3 (659.248, 42.0345, 997.274),
		Rotation: new THREE.Vector3 (0.0,67.6143,0.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicChristina',
		Titel: 'CAMTARGET_MenschenPicChristina',
		Position: new THREE.Vector3 (779.45, 31.3008, 334.636),
		Rotation: new THREE.Vector3 (0.0,-30.5477,0.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicBerggasse',
		Titel: 'CAMTARGET_MenschenPicBerggasse',
		Position: new THREE.Vector3 (-151.721, 41.6631, 889.866),
		Rotation: new THREE.Vector3 (180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicAuslegerSalz',
		Titel: 'CAMTARGET_MenschenPicAuslegerSalz',
		Position: new THREE.Vector3 (625.41, 31.3008, 330.059),
		Rotation: new THREE.Vector3 (0.0,-30.5477,0.0)
	},
	{
		Id: 'CAMTARGET_MenschenPicAufsumpfen',
		Titel: 'CAMTARGET_MenschenPicAufsumpfen',
		Position: new THREE.Vector3 (-163.882, 38.4315, 680.267),
		Rotation: new THREE.Vector3 (180.0,13.3648,180.0)
	},
	{
		Id: 'CAMTARGET_Bergarbeiter',
		Titel: 'CAMTARGET_Bergarbeiter',
		Position: new THREE.Vector3 (294.545, 17.8342, 534.084),
		Rotation: new THREE.Vector3 (-180.0,13.3648,180.0)
	},
	{
		Id: 'CAM_VideoPoint_ZeitzeugeSalomon',
		Titel: 'CAM_VideoPoint_ZeitzeugeSalomon',
		Position: new THREE.Vector3 (780.698, 25.3787, 151.21),
		Rotation: new THREE.Vector3 (-87.0435,59.3248,2.54335)
	},
	{
		Id: 'CAM_VideoPoint_Zeitzeuge',
		Titel: 'CAM_VideoPoint_Zeitzeuge',
		Position: new THREE.Vector3 (746.158, 25.5351, 91.1236),
		Rotation: new THREE.Vector3 (-87.0435,59.3248,2.54335)
	},
	{
		Id: 'CAM_VideoPoint_PaniKucera',
		Titel: 'CAM_VideoPoint_PaniKucera',
		Position: new THREE.Vector3 (-39.9913, 25.3891, 534.668),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_VideoPoint_HallstaetterVolksfest',
		Titel: 'CAM_VideoPoint_HallstaetterVolksfest',
		Position: new THREE.Vector3 (-39.6256, 25.1062, 839.477),
		Rotation: new THREE.Vector3 (-87.0435,59.3248,2.54335)
	},
	{
		Id: 'CAM_VideoPoint_Habsburger',
		Titel: 'CAM_VideoPoint_Habsburger',
		Position: new THREE.Vector3 (660.862, 26.07, 588.469),
		Rotation: new THREE.Vector3 (-87.0435,59.3248,2.54335)
	},
	{
		Id: 'CAM_VideoPoint_GloecklerFilm',
		Titel: 'CAM_VideoPoint_GloecklerFilm',
		Position: new THREE.Vector3 (-80.8639, 25.5249, 821.416),
		Rotation: new THREE.Vector3 (-87.0435,59.3248,2.54335)
	},
	{
		Id: 'CAM_VideoPoint_Gedenkstaette',
		Titel: 'CAM_VideoPoint_Gedenkstaette',
		Position: new THREE.Vector3 (711.431, 25.1354, 29.7185),
		Rotation: new THREE.Vector3 (-87.0435,59.3248,2.54335)
	},
	{
		Id: 'CAM_VideoPoint_DieLandler',
		Titel: 'CAM_VideoPoint_DieLandler',
		Position: new THREE.Vector3 (371.351, 25.4424, 976.761),
		Rotation: new THREE.Vector3 (-87.0435,59.3248,2.54335)
	},
	{
		Id: 'CAM_VideoPoint_BrauchtumsMasken',
		Titel: 'CAM_VideoPoint_BrauchtumsMasken',
		Position: new THREE.Vector3 (-39.9893, 25.108, 884.881),
		Rotation: new THREE.Vector3 (-87.0435,59.3248,2.54335)
	},
	{
		Id: 'CAM_VideoPoint_AusseerFasching',
		Titel: 'CAM_VideoPoint_AusseerFasching',
		Position: new THREE.Vector3 (18.965, 25.2695, 804.97),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicVorstaende',
		Titel: 'CAM_MenschenPicVorstaende',
		Position: new THREE.Vector3 (-219.017, 34.604, 653.508),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicTrageweiber',
		Titel: 'CAM_MenschenPicTrageweiber',
		Position: new THREE.Vector3 (-248.764, 36.999, 586.578),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicSalzSchiffFahne',
		Titel: 'CAM_MenschenPicSalzSchiffFahne',
		Position: new THREE.Vector3 (636.163, 42.9644, 839.412),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicSalzamtTafel',
		Titel: 'CAM_MenschenPicSalzamtTafel',
		Position: new THREE.Vector3 (690.135, 32.6, 340.795),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicReproFasching',
		Titel: 'CAM_MenschenPicReproFasching',
		Position: new THREE.Vector3 (-9.00381, 37.7125, 735.506),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicPferd',
		Titel: 'CAM_MenschenPicPferd',
		Position: new THREE.Vector3 (594.052, 32.6, 395.937),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicMessen',
		Titel: 'CAM_MenschenPicMessen',
		Position: new THREE.Vector3 (-80.5858, 37.9086, 572.696),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicMaria',
		Titel: 'CAM_MenschenPicMaria',
		Position: new THREE.Vector3 (760.309, 32.6, 400.816),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicLandler',
		Titel: 'CAM_MenschenPicLandler',
		Position: new THREE.Vector3 (484.161, 41.4047, 1012.15),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicKZ_F',
		Titel: 'CAM_MenschenPicKZ_F',
		Position: new THREE.Vector3 (539.167, 26.1888, 142.912),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicKZ_E',
		Titel: 'CAM_MenschenPicKZ_E',
		Position: new THREE.Vector3 (589.161, 26.1888, 114.836),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicKZ_D',
		Titel: 'CAM_MenschenPicKZ_D',
		Position: new THREE.Vector3 (659.115, 26.1753, 135.576),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicKZ_C',
		Titel: 'CAM_MenschenPicKZ_C',
		Position: new THREE.Vector3 (643.87, 27.6457, 224.479),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicKZ_B',
		Titel: 'CAM_MenschenPicKZ_B',
		Position: new THREE.Vector3 (600.442, 27.6457, 252.889),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicKZ_A',
		Titel: 'CAM_MenschenPicKZ_A',
		Position: new THREE.Vector3 (519.251, 25.925, 213.023),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicKinderC',
		Titel: 'CAM_MenschenPicKinderC',
		Position: new THREE.Vector3 (-285.782, 30.777, 581.071),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicKinderB',
		Titel: 'CAM_MenschenPicKinderB',
		Position: new THREE.Vector3 (-231.408, 30.777, 550.696),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicKinderA',
		Titel: 'CAM_MenschenPicKinderA',
		Position: new THREE.Vector3 (-177.3, 37.7064, 536.859),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicKarl',
		Titel: 'CAM_MenschenPicKarl',
		Position: new THREE.Vector3 (713.048, 32.6, 319.138),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicJosef',
		Titel: 'CAM_MenschenPicJosef',
		Position: new THREE.Vector3 (783.645, 32.6, 441.846),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicHoschzeit',
		Titel: 'CAM_MenschenPicHoschzeit',
		Position: new THREE.Vector3 (689.774, 25.8091, 447.673),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicHerztraeger',
		Titel: 'CAM_MenschenPicHerztraeger',
		Position: new THREE.Vector3 (-83.0794, 42.0818, 499.919),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicGoldHaubenFrau',
		Titel: 'CAM_MenschenPicGoldHaubenFrau',
		Position: new THREE.Vector3 (-34.2651, 39.8884, 904.769),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicFranzOld',
		Titel: 'CAM_MenschenPicFranzOld',
		Position: new THREE.Vector3 (779.989, 31.162, 488.555),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicFranz',
		Titel: 'CAM_MenschenPicFranz',
		Position: new THREE.Vector3 (809.189, 32.6, 484.108),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},
	{
		Id: 'CAM_MenschenPicFasching',
		Titel: 'CAM_MenschenPicFasching',
		Position: new THREE.Vector3 (58.308, 33.6885, 667.63),
		Rotation: new THREE.Vector3 (-85.9336,-30.5866,-2.07173)
	},
	{
		Id: 'CAM_MenschenPicEbensee',
		Titel: 'CAM_MenschenPicEbensee',
		Position: new THREE.Vector3 (-134.282, 38.5704, 801.278),
		Rotation: new THREE.Vector3 (-89.5804,-30.1491,-0.210744)
	},
	{
		Id: 'CAM_MenschenPicDepoWege',
		Titel: 'CAM_MenschenPicDepoWege',
		Position: new THREE.Vector3 (619.563, 41.3404, 925.902),
		Rotation: new THREE.Vector3 (89.5849,29.0722,179.798)
	},
	{
		Id: 'CAM_MenschenPicChristina',
		Titel: 'CAM_MenschenPicChristina',
		Position: new THREE.Vector3 (736.982, 32.6, 359.793),
		Rotation: new THREE.Vector3 (-89.2881,59.3563,0.61246)
	},
	{
		Id: 'CAM_MenschenPicBerggasse',
		Titel: 'CAM_MenschenPicBerggasse',
		Position: new THREE.Vector3 (-86.7131, 41.2025, 853.506),
		Rotation: new THREE.Vector3 (89.2568,-60.7766,-179.351)
	},
	{
		Id: 'CAM_MenschenPicAuslegerSalz',
		Titel: 'CAM_MenschenPicAuslegerSalz',
		Position: new THREE.Vector3 (646.075, 32.6, 367.1),
		Rotation: new THREE.Vector3 (-89.5845,-29.1565,-0.202422)
	},
	{
		Id: 'CAM_MenschenPicAufsumpfen',
		Titel: 'CAM_MenschenPicAufsumpfen',
		Position: new THREE.Vector3 (-189.949, 38.4313, 635.871),
		Rotation: new THREE.Vector3 (89.5793,30.419,179.787)
	},
	{
		Id: 'CAM_Bergarbeiter',
		Titel: 'CAM_Bergarbeiter',
		Position: new THREE.Vector3 (251.245, 56.7734, 458.568),
		Rotation: new THREE.Vector3 (-87.0436,59.3248,2.54333)
	},






	//////////Holzarbeit






	{
		Id: 'CAMTARGET_VideoPoint_FilmHolz',
		Titel: 'CAMTARGET_VideoPoint_FilmHolz',
		Position: new THREE.Vector3 (42.3026, 12.9133, -547.089),
		Rotation: new THREE.Vector3 (-180.0,-72.5237,180.0)
	},
	{
		Id: 'CAMTARGET_VideoPoint_Chorinskyklause',
		Titel: 'CAMTARGET_VideoPoint_Chorinskyklause',
		Position: new THREE.Vector3 (63.0756, 14.1062, -578.8),
		Rotation: new THREE.Vector3 (-180.0,-72.5237,180.0)
	},
	{
		Id: 'CAMTARGET_SolePfanneTopView',
		Titel: 'CAMTARGET_SolePfanneTopView',
		Position: new THREE.Vector3 (538.867, 30.4146, -476.151),
		Rotation: new THREE.Vector3 (-180.0,-72.5237,180.0)
	},
	{
		Id: 'CAMTARGET_SchiffsWerkstatt',
		Titel: 'CAMTARGET_SchiffsWerkstatt',
		Position: new THREE.Vector3 (506.434, 33.6054, -633.016),
		Rotation: new THREE.Vector3 (-180.0,-72.5237,180.0)
	},
	{
		Id: 'CAMTARGET_SchachtArbeitGlows',
		Titel: 'CAMTARGET_SchachtArbeitGlows',
		Position: new THREE.Vector3 (524.79, 30.0863, -358.754),
		Rotation: new THREE.Vector3 (-180.0,-72.5237,180.0)
	},
	{
		Id: 'CAMTARGET_SchachtArbeit',
		Titel: 'CAMTARGET_SchachtArbeit',
		Position: new THREE.Vector3 (407.576, 28.1703, -160.891),
		Rotation: new THREE.Vector3 (180.0,-72.5238,180.0)
	},
	{
		Id: 'CAMTARGET_Position_Artefakt_Salzschiff',
		Titel: 'CAMTARGET_Position_Artefakt_Salzschiff',
		Position: new THREE.Vector3 (413.304, 9.6475, -665.571),
		Rotation: new THREE.Vector3 (-180.0,-72.5237,180.0)
	},
	{
		Id: 'CAMTARGET_MannInStollen',
		Titel: 'CAMTARGET_MannInStollen',
		Position: new THREE.Vector3 (495.318, 33.5437, -303.256),
		Rotation: new THREE.Vector3 (-180.0,-72.5238,180.0)
	},
	{
		Id: 'CAMTARGET_HolzTimeMachine',
		Titel: 'CAMTARGET_HolzTimeMachine',
		Position: new THREE.Vector3 (294.029, -3.02744, -482.513),
		Rotation: new THREE.Vector3 (-180.0,-72.5237,180.0)
	},
	{
		Id: 'CAMTARGET_HolzarbeiterBilder',
		Titel: 'CAMTARGET_HolzarbeiterBilder',
		Position: new THREE.Vector3 (6.14395, 33.6613, -508.459),
		Rotation: new THREE.Vector3 (-180.0,-72.5237,180.0)
	},
	{
		Id: 'CAMTARGET_BrennendesHaus',
		Titel: 'CAMTARGET_BrennendesHaus',
		Position: new THREE.Vector3 (318.588, 29.6093, -137.915),
		Rotation: new THREE.Vector3 (0.0,0.0,0.0)
	},
	{
		Id: 'CAMTARGET_Bauweise',
		Titel: 'CAMTARGET_Bauweise',
		Position: new THREE.Vector3 (329.777, 32.389, -727.495),
		Rotation: new THREE.Vector3 (-180.0,-72.5237,180.0)
	},
	{
		Id: 'CAM_VideoPoint_FilmHolz',
		Titel: 'CAM_VideoPoint_FilmHolz',
		Position: new THREE.Vector3 (57.574, 21.7332, -538.882),
		Rotation: new THREE.Vector3 (88.8695,60.45,179.016)
	},
	{
		Id: 'CAM_VideoPoint_Chorinskyklause',
		Titel: 'CAM_VideoPoint_Chorinskyklause',
		Position: new THREE.Vector3 (75.3593, 22.0074, -571.306),
		Rotation: new THREE.Vector3 (88.8695,60.45,179.016)
	},
	{
		Id: 'CAM_SolePfanneTopView',
		Titel: 'CAM_SolePfanneTopView',
		Position: new THREE.Vector3 (521.359, 30.7425, -445.387),
		Rotation: new THREE.Vector3 (88.8695,60.45,179.016)
	},
	{
		Id: 'CAM_SchiffsWerkstatt',
		Titel: 'CAM_SchiffsWerkstatt',
		Position: new THREE.Vector3 (485.324, 33.6277, -597.383),
		Rotation: new THREE.Vector3 (88.8695,60.45,179.016)
	},
	{
		Id: 'CAM_SchachtArbeitGlows',
		Titel: 'CAM_SchachtArbeitGlows',
		Position: new THREE.Vector3 (484.709, 30.5346, -381.472),
		Rotation: new THREE.Vector3 (88.8695,60.45,179.016)
	},
	{
		Id: 'CAM_SchachtArbeit',
		Titel: 'CAM_SchachtArbeit',
		Position: new THREE.Vector3 (350.56, 29.9436, -192.555),
		Rotation: new THREE.Vector3 (87.5495,60.9333,177.858)
	},
	{
		Id: 'CAM_Position_Artefakt_Salzschiff',
		Titel: 'CAM_Position_Artefakt_Salzschiff',
		Position: new THREE.Vector3 (389.872, 41.5, -626.018),
		Rotation: new THREE.Vector3 (88.8695,60.45,179.016)
	},
	{
		Id: 'CAM_MannInStollen',
		Titel: 'CAM_MannInStollen',
		Position: new THREE.Vector3 (455.634, 33.9875, -325.749),
		Rotation: new THREE.Vector3 (88.8695,60.45,179.016)
	},
	{
		Id: 'CAM_HolzTimeMachine',
		Titel: 'CAM_HolzTimeMachine',
		Position: new THREE.Vector3 (267.118, 55.5284, -437.406),
		Rotation: new THREE.Vector3 (88.8695,60.45,179.016)
	},
	{
		Id: 'CAM_HolzarbeiterBilder',
		Titel: 'CAM_HolzarbeiterBilder',
		Position: new THREE.Vector3 (47.0121, 33.6905, -484.62),
		Rotation: new THREE.Vector3 (88.8695,60.45,179.016)
	},
	{
		Id: 'CAM_BrennendesHaus',
		Titel: 'CAM_BrennendesHaus',
		Position: new THREE.Vector3 (339.986, 30.4782, -173.848),
		Rotation: new THREE.Vector3 (88.6148,-30.7667,-179.291)
	},
	{
		Id: 'CAM_Bauweise',
		Titel: 'CAM_Bauweise',
		Position: new THREE.Vector3 (306.345, 32.4138, -687.942),
		Rotation: new THREE.Vector3 (88.8695,60.45,179.016)
	},




/////////////Klima









{
	Id: 'CAMTARGET_PicZeittafel',
	Titel: 'CAMTARGET_PicZeittafel',
	Position: new THREE.Vector3 (-348.789, 18.4076, -216.58),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_PicKarte',
	Titel: 'CAMTARGET_PicKarte',
	Position: new THREE.Vector3 (-399.617, 29.2758, -139.888),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_PicVerschuettung04',
	Titel: 'CAMTARGET_PicVerschuettung04',
	Position: new THREE.Vector3 (165.202, 15.8125, -431.214),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_PicVerschuettung03',
	Titel: 'CAMTARGET_PicVerschuettung03',
	Position: new THREE.Vector3 (97.9016, 15.8125, -428.529),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_PicVerschuettung02',
	Titel: 'CAMTARGET_PicVerschuettung02',
	Position: new THREE.Vector3 (31.4407, 15.8125, -419.723),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_PicVerschuettung01',
	Titel: 'CAMTARGET_PicVerschuettung01',
	Position: new THREE.Vector3 (-30.6181, 19.3435, -406.453),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_Katastrophe05',
	Titel: 'CAMTARGET_Katastrophe05',
	Position: new THREE.Vector3 (81.4134, 17.1107, -613.114),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_Katastrophe04',
	Titel: 'CAMTARGET_Katastrophe04',
	Position: new THREE.Vector3 (-6.77615, 17.0483, -644.558),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_Katastrophe03',
	Titel: 'CAMTARGET_Katastrophe03',
	Position: new THREE.Vector3 (-27.2828, 17.0483, -552.982),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_Katastrophe02',
	Titel: 'CAMTARGET_Katastrophe02',
	Position: new THREE.Vector3 (-103.352, 16.6551, -598.391),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAMTARGET_Katastrophe01',
	Titel: 'CAMTARGET_Katastrophe01',
	Position: new THREE.Vector3 (-94.8307, 16.6551, -504.933),
	Rotation: new THREE.Vector3 (0.0,0.0,0.0)
},
{
	Id: 'CAM_PicZeittafel',
	Titel: 'CAM_PicZeittafel',
	Position: new THREE.Vector3 (-320.508, 18.8795, -232.527),
	Rotation: new THREE.Vector3 (88.0579,-60.5684,-178.308)
},
{
	Id: 'CAM_PicKarte',
	Titel: 'CAM_PicKarte',
	Position: new THREE.Vector3 (-359.635, 29.943, -162.433),
	Rotation: new THREE.Vector3 (88.3049,-60.5717,-178.524)
},
{
	Id: 'CAM_PicVerschuettung04',
	Titel: 'CAM_PicVerschuettung04',
	Position: new THREE.Vector3 (136.274, 16.1888, -413.459),
	Rotation: new THREE.Vector3 (-88.7859,58.4539,1.03472)
},
{
	Id: 'CAM_PicVerschuettung03',
	Titel: 'CAM_PicVerschuettung03',
	Position: new THREE.Vector3 (70.1794, 16.3444, -412.671),
	Rotation: new THREE.Vector3 (-88.0789,60.2151,1.66746)
},
{
	Id: 'CAM_PicVerschuettung02',
	Titel: 'CAM_PicVerschuettung02',
	Position: new THREE.Vector3 (3.71842, 16.3444, -403.865),
	Rotation: new THREE.Vector3 (-88.0789,60.2151,1.66746)
},
{
	Id: 'CAM_PicVerschuettung01',
	Titel: 'CAM_PicVerschuettung01',
	Position: new THREE.Vector3 (-61.3993, 21.5058, -388.125),
	Rotation: new THREE.Vector3 (-83.2713,59.0548,5.77793)
},
{
	Id: 'CAM_Katastrophe05',
	Titel: 'CAM_Katastrophe05',
	Position: new THREE.Vector3 (50.6418, 17.7011, -595.512),
	Rotation: new THREE.Vector3 (-88.0789,60.2151,1.66746)
},
{
	Id: 'CAM_Katastrophe04',
	Titel: 'CAM_Katastrophe04',
	Position: new THREE.Vector3 (-37.5478, 17.6387, -626.955),
	Rotation: new THREE.Vector3 (-88.0789,60.2151,1.66746)
},
{
	Id: 'CAM_Katastrophe03',
	Titel: 'CAM_Katastrophe03',
	Position: new THREE.Vector3 (-58.0544, 17.6387, -535.38),
	Rotation: new THREE.Vector3 (-88.0789,60.2151,1.66746)
},
{
	Id: 'CAM_Katastrophe02',
	Titel: 'CAM_Katastrophe02',
	Position: new THREE.Vector3 (-131.074, 17.187, -582.533),
	Rotation: new THREE.Vector3 (-88.0789,60.215,1.66747)
},
{
	Id: 'CAM_Katastrophe01',
	Titel: 'CAM_Katastrophe01',
	Position: new THREE.Vector3 (-122.553, 17.187, -489.075),
	Rotation: new THREE.Vector3 (-88.0789,60.2151,1.66746)
},

]



