import { useRef, useEffect } from 'react';
import { statePlayerPositionS } from '../store';
import { useSnapshot } from 'valtio';

const useMouseInput = (stateTemp) => {
  const snapPlayerInputActive = useSnapshot(statePlayerPositionS)
  const trackingRef = useRef(false);
  const mouse = useRef({ x: 0, y: 0 });

  const startTracking = () => {
    if (stateTemp.startInteract) {
      document.body.style.cursor = "none";
      trackingRef.current = true;
      statePlayerPositionS.isTracking = true
    }
  };

  const stopTracking = () => {
    document.body.style.cursor = "auto";
    trackingRef.current = false;
    mouse.current.x = 0;
    mouse.current.y = 0;
    statePlayerPositionS.isTracking = false


  };

  const trackMouse = (event) => {
    if (!trackingRef.current) return;
    document.body.style.cursor = "none";
    const targetX = (event.clientX / window.innerWidth ) * 2 - 1;


    const dampingFactor = 0.35;

    mouse.current.x = ((targetX - mouse.current.x) * dampingFactor)
    mouse.current.y = (((event.clientY / window.innerHeight /3 ) * 2) - 1)



    const x = easeValue(mouse.current.x)
    const y = easeValue(mouse.current.y)
//     mouse.current.set(x,y,0)
// mouse.current.n

    // Update your logic with the new values (e.g., emit an event, update state, etc.)
    // console.log('Mouse position:', x, y);
  };

  // const trackTouch = (event) => {
  //   if (!trackingRef.current) return;

  //   const touch = event.touches[0];

  //   mouse.current.x = (touch.clientX / window.innerWidth) * 2 - 1;
  //   mouse.current.y = (touch.clientY / window.innerHeight) * 0.5 + 0.5

  //   const x = easeValue(mouse.current.x);
  //   const y = easeValue(mouse.current.y);

  //   // Update your logic with the new values (e.g., emit an event, update state, etc.)
  //   // console.log('Touch position:', x, y);
  //   mouse.set(x,y,0)
  //   mouse.normalize()
  // };

  const easeValue = (value) => {
  


    // Adjust the easing function as per your requirements
    return value * value
   
    // Adjust the easing function as per your requirements
   
  };



  useEffect(() => {

   
    const handleMouseDown = () => startTracking();
    const handleMouseUp = () => stopTracking();
    const handleMouseMove = (event) => trackMouse(event);
    const handleTouchStart = () => startTracking();
    const handleTouchEnd = () => stopTracking();
    const handleTouchMove = (event) => trackTouch(event);

    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);
    document.addEventListener('touchmove', handleTouchMove);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  return { mouse };
};

export default useMouseInput;
