import "./ToolBar.css";
import { motion, AnimatePresence } from "framer-motion";
import stateOrbitCamPos from "../EntryRoom/states/stateOrbitCamPos.jsx";
import { stateOrbitLocation } from "../store";
import { stateInArea } from "../store";
import { useSnapshot } from "valtio";
import { stateUiDisplay } from "../store";
import InfoWindow from "./InfoWindow";
 import textDataDE from "../EntryRoom/text/entryRoomText.json";
 import textDataEN from "../EntryRoom/text/entryRoomText_en.json";
import { useEffect, useState } from "react";
import Sound from "../Utils/Sound";
import { useMemo } from "react";
import { lazy } from "react";
import { stateEnglisch } from "../store";
import React from "react";
import { useLayoutEffect } from "react";




export default function ToolBar() {
  const snapInArea = useSnapshot(stateInArea);
  const snapUiDisplay = useSnapshot(stateUiDisplay);
  const [matchingText, setMatchingText] = useState();
  const [hintText, setHintText] = useState("");
  const [matchingOrbit, setMatchingOrbit] = useState();
  const [prevOrbit, setPrevOrbit] = useState();
  const snapIsEnglisch = useSnapshot(stateEnglisch)






  const {
    playFly,

  } = Sound();
  

  //Check if InfoWindow has Text
  useEffect(() => {
    // playCheckArea()
   const textData = snapIsEnglisch.isEnglisch ? textDataEN : textDataDE
    const textCurrentIndex = snapInArea.inAreaIndex;

    const findObjectById = (id) => {
      return textData.find((item) => item.Id === id) || null;
    };

    const matchingTextKon = findObjectById(textCurrentIndex);

    setMatchingText(matchingTextKon);

    if (matchingTextKon) {
      stateUiDisplay.storyTextMatch = true;
      setHintText(matchingTextKon.Hint);
    } else {
      stateUiDisplay.storyTextMatch = false;
      setHintText("");
    }


  }, [snapInArea]);


  

  //Check if Orbit has Data
  useEffect(() => {
    
    const textCurrentIndex = snapInArea.inAreaIndex;




    const findObjectById = (id) => {
      return (
        stateOrbitCamPos.find((item) => item.Id.replace("CAM_", "") === id) ||
        null
      );
    };

    const matchingOrbitKon = findObjectById(textCurrentIndex);

    if (matchingOrbitKon) {
      setMatchingOrbit(true);
    } else {
      setMatchingOrbit(false);
    }

  }, [snapInArea.inArea]);



  return (
    <>
      <AnimatePresence>
        {snapInArea.inArea ? (
          <ToolBarKon
            matchingText={matchingText}
            hintText={hintText}
            matchingOrbit={matchingOrbit}
            playFly= {playFly}
          
          />
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {snapInArea.inArea &&
        snapUiDisplay.storyTextMatch &&
        matchingText &&
        snapUiDisplay.openInfoWindow ? (
          <InfoWindow matchingText={matchingText} />
        ) : null}
      </AnimatePresence>
    </>
  );
}

function ToolBarKon({ matchingText, matchingOrbit, hintText,playFly }) {




  // // Memoize the image element
  // const orbitImage = useMemo(() => (
  //   <img
  //     className="ToolBarImages"
  //     src="../UI/OrbitButton.png"
  //     alt="Orbit Controls"
  //     onClick={handleToggleOrbitView}
  //     style={{ display: isOrbitImageLoaded ? 'inline' : 'none' }}
  //   />
  // ), [isOrbitImageLoaded]);

  const snapUiDisplay = useSnapshot(stateUiDisplay);
  const snapLerpRuns = useSnapshot(stateOrbitLocation);
  const snapInArea = useSnapshot(stateInArea);
  const snapOrbitLocation = useSnapshot(stateOrbitLocation);
  const orbitCurrentIndex = snapInArea.inAreaIndex;

  const [hintVisible, setHintVisible] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [storyClicked, setStoryClicked] = useState(false);
  // const [hintText, setHintText] = useState("")

  useEffect(() => {
    if (snapUiDisplay.storyTextMatch) {
      const hintTimeout = setTimeout(() => {
        setHintVisible(false);
      }, 7500); // 5000 milliseconds = 5 seconds

      return () => clearTimeout(hintTimeout); // Clean up the timer on unmount
    }
  }, [snapUiDisplay.storyTextMatch]);




  const findObjectById = (id) => {
    return (
      stateOrbitCamPos.find((item) => item.Id.replace("CAM_", "") === id) ||
      null
    );
  };

  //Check ID
  const findTargetById = (id) => {
    return (
      stateOrbitCamPos.find(
        (item) => item.Id.replace("CAMTARGET_", "") === id
      ) || null
    );
  };

const matchingObject = findObjectById(orbitCurrentIndex);
const matchingTarget = findTargetById(orbitCurrentIndex);

const arrayNoOrbit = ["Position_Artefakt_Tuellenbeil","Position_Artefakt_Situla","Position_Artefakt_Lanzenspitze","Position_Artefakt_EiserneSpeerspitze", "Position_Artefakt_EisenbeilHolzstueck","Position_Artefakt_BronzeArmring","Position_Artefakt_Brillenfibel","Position_Artefakt_EisenSchwert","Position_Artefakt_Mehrkopfnadel","Position_Artefakt_Breitrandschale" ]

  useEffect(()=>{

    if(!snapOrbitLocation.orbitClicked && !snapLerpRuns.lerpRuns)
    {
      setClicked(false);
  }
  },[snapOrbitLocation.orbitClicked])

  function handleToggleOrbitView() {
    playFly()
    if (!snapLerpRuns.lerpRuns) {
      stateOrbitLocation.orbitClicked = !stateOrbitLocation.orbitClicked;
      setClicked(!clicked);

      if (matchingObject && !arrayNoOrbit.includes(orbitCurrentIndex) ) {
        stateOrbitLocation.CameraPos.set(...matchingObject.Position);
        stateOrbitLocation.TargetPos.set(...matchingTarget.Position);
      }

      // Toggle the orbitClicked state
      // stateOrbitLocation.orbitClicked = !stateOrbitLocation.orbitClicked
    }
  }
// useEffect(()=>{

//   if(!snapInArea.inArea)
//   {
//     stateUiDisplay.openInfoWindow = !stateUiDisplay.openInfoWindow;
//   }


// },[snapInArea.inArea === false])

  useEffect(()=>{

    if(stateUiDisplay.openInfoWindow){
    setStoryClicked(true)
  }
  else if(!stateUiDisplay.openInfoWindow)
  {
    setStoryClicked(false)
  }
  },[stateUiDisplay.openInfoWindow])



  function handleToggleStoryWindow() {

    stateUiDisplay.openInfoWindow = !stateUiDisplay.openInfoWindow;

  }

  return (
    <>
    
      <div className="hint">{hintText}</div>
      
        <motion.div
          className="ToolbarContainer"
          initial={{  x: -105 }}
          animate={{  x: 0 }}
          exit={{  x: -105  }} // Define exit animation here
          transition={{  type: "spring", duration: 0.8, delay: 0.3, damping: 5,
          stiffness: 100,
        
         restDelta: 0.001 }}
        >
          
           
              <motion.div
                 className={`circleToolbar ${clicked ? "clicked" : ""} ${matchingOrbit ? "" : "hidden"}`}
                animate={{
                  // speed:0.1,
                  scale: [1, 1, 1.3, 1.3, 1],
                  // rotate: [0, 360, 360, 360, 0],
                  // borderRadius: ["50%", "0%", "0%", "0%", "50%"],
                  backgroundColor: [
                    "rgba(0, 0, 0, 0.3)",
                    "rgba(255, 162, 0, 1)",
                    "rgba(255, 162, 0, 1)",
                    "rgba(0, 0, 0, 0.3)",
                  ],
                }}
                transition={{
                  duration: 0.65,
                  ease: "easeInOut",
                  times: [0, 0.2, 0.5, 0.8, 1],
                  repeat: 1,
                }}
              >
                <img
                  className="ToolBarImages"
                  src="../UI/OrbitButton.png"
                  alt="Orbit Controls"
                  onClick={handleToggleOrbitView}
                  
                />

            
                  {hintVisible && <div className="hintStatic">ZOOM</div>}
              
              </motion.div>
       
         
              <motion.div
                className={`circleToolbar ${storyClicked ? "clicked" : ""} : ""} ${snapUiDisplay.storyTextMatch ? "" : "hidden"}`}
                animate={{
                  // speed:0.1,
                  scale: [1, 1, 1.3, 1.3, 1],
                  // rotate: [0, 360, 360, 360, 0],
                  // borderRadius: ["50%", "0%", "0%", "0%", "50%"],
                  backgroundColor: [
                    "rgba(0, 0, 0, 0.3)",
                    "rgba(255, 162, 0, 0.573)",
                    "rgba(255, 162, 0, 0.573)",
                    "rgba(0, 0, 0, 0.3)",
                  ],
                }}
                transition={{
                  duration: 0.65,
                  ease: "easeInOut",
                  times: [0, 0.2, 0.5, 0.8, 1],
                  repeat: 1,
                  // repeatDelay: 1
                  delay: 1.5,
                }}
                onAnimationComplete={() => setHintVisible(true)}
              >
                <img
                  className="ToolBarImages"
                  src="../UI/StoryButton.png"
                  alt="Orbit Controls"
                  onClick={handleToggleStoryWindow}
                />
                 {hintVisible && <div className="hintStaticStory ">INFO</div>}
                {/* {hintVisible && <div className="hint">{matchingText.Hint}</div>} */}
              </motion.div>
         
      
        </motion.div>

    </>
  );
}
