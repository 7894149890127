import { Box, Clone, useAnimations } from "@react-three/drei";
import { useEffect, useState, useRef, useMemo } from "react";
import * as THREE from "three";
import GlbLoader from "../Utils/GlbLoader";
import { stateInAreaRooms, stateWallPush,statePlayerPositionS } from "../store";
import { useSnapshot } from "valtio";

import Sound from "../Utils/Sound";
import stateMuseumPoints from "./states/stateMuseumPoints";

export default function Pointer({ setDoorCloseAnimationEnd }) {
  const [clones, setClones] = useState([]);

  const snapWallPush = useSnapshot(stateWallPush);

  const mPointsLength = stateMuseumPoints.length;

  const { playClickSound, playMouseIn, playMouseOut, playDoorOpen } = Sound();

  const handlePointerDown = (index) => {
    if (index === snapWallPush.activeThemeIndex) {
      stateWallPush.activeThemeIndex = -1;
    } else if (
      stateWallPush.activeThemeIndex != -1 &&
      index != snapWallPush.activeThemeIndex
    ) {
      stateWallPush.activeThemeIndex = -1;
    } else {
      stateWallPush.activeThemeIndex = index;
    }
  };

  const clonesData = useMemo(() => {
    return [...Array(mPointsLength).keys()].map((x) => {
      return {
        key: x,
        position: [...stateMuseumPoints[x].Position],
        rotation: [...stateMuseumPoints[x].Rotation],
        index: [...stateMuseumPoints[x].Id],
        Id: x,
      };
    });
  }, [mPointsLength, stateMuseumPoints]);

  useEffect(() => {
    setClones(clonesData);
  }, [clonesData]);

  return (
    <>
      {clones.map((clone) => (
        <PointerKon
          key={clone.key}
          position={clone.position}
          rotation={clone.rotation}
          Id={clone.Id}
          onPointerDown={() => handlePointerDown(clone.Id)}
          active={stateWallPush.activeThemeIndex}
          setDoorCloseAnimationEnd={setDoorCloseAnimationEnd}
          playMouseIn={playMouseIn}
          playMouseOut={playMouseOut}
          playDoorOpen={playDoorOpen}
        />
      ))}
    </>
  );
}



function PointerKon({
  scale,
  position,
  rotation,
  onPointerDown,
  Id,
  setDoorCloseAnimationEnd,
  active,
  playMouseIn,
  playMouseOut,
  playDoorOpen,
}) {
  const rotationInRadians = [
    THREE.MathUtils.degToRad(rotation[0]),
    THREE.MathUtils.degToRad(-rotation[2]),
    THREE.MathUtils.degToRad(rotation[0]),
  ];

  // const modelRef = useRef()
  const snapWallPush = useSnapshot(stateWallPush);

  const [firstPush, setFirstPush] = useState(false);
  const {
    pointerModel,
    doorButton,
    buttonHoverMaterial,
    buttonOriginalMaterial,
  } = GlbLoader();

  const memoizeddoorButton = useMemo(() => doorButton, []);
  const memoizedButtonHoverMaterial = useMemo(() => buttonHoverMaterial.clone(), []);
  const memoizedButtonOriginalMaterial = useMemo(() => buttonOriginalMaterial.clone(), []);

  const ref = useRef();

  const { actions, names, mixer } = useAnimations(pointerModel.animations, ref);

  const snapStateInAreaRooms = useSnapshot(stateInAreaRooms);
  const snapPlayerPosition = useSnapshot(statePlayerPositionS)
  const [hovered, setHover] = useState();

  const ButtonRef = useRef();

  const [doorClosed, setDoorClosed] = useState();
  const [pause, setPause] = useState(true);
  const [highlight, setHighlight] = useState();
  const [buttonObject, setButtonObject] = useState()
  
  useEffect(() => {
    
    // setButtonObject( ButtonRef.current.getObjectByName("Button001"));
    // buttonObject = ButtonRef.current.getObjectByName("Button001");
    setHighlight(ButtonRef.current.getObjectByName("Button001"))
    if (highlight) {
      highlight.material = doorButton.material;
    }


  }, []);

  const ButtonToHover = () => {
  
    if (highlight) {
      highlight.material = memoizedButtonHoverMaterial;
     
    }
  };

  const ButtonToOriginal = () => {
    if (highlight) {
      highlight.material = memoizedButtonOriginalMaterial;
    }
  };

  useEffect(() => {
    // function onReversedAnimationFinished(e) {
    //   setDoorCloseAnimationEnd(true);
    // }

    const action = actions[names[0]];
    // action.reset();
    action.clampWhenFinished = true;
    action.setLoop(THREE.LoopOnce, 1);

    // setDoorCloseAnimationEnd(false)
    if (
      doorClosed &&
      stateWallPush.activeThemeIndex === Id &&
      snapStateInAreaRooms.inAreaRoomEntryRoom
    ) {
      if (stateWallPush.activeThemeIndex != -1) {
        ButtonToHover();
      } else {
        ButtonToOriginal();
      }

      playDoorOpen();
      action.reset();
      action.timeScale = 1;
      action.play();
      setDoorClosed(false);
    } else if (
      !doorClosed &&
      stateWallPush.activeThemeIndex != Id &&
      snapStateInAreaRooms.inAreaRoomEntryRoom
    ) {
      // setDoorCloseAnimationEnd(false)
      ButtonToOriginal();
      if (firstPush) {
        playDoorOpen();
      }
      action.paused = false;
      action.reset();
      action.time = action.getClip().duration;
      action.timeScale = -1;

      action.play();

      // mixer.removeEventListener('finished', onReversedAnimationFinished);
      // mixer.addEventListener('finished', onReversedAnimationFinished);

      setDoorClosed(true);
    }
    //  else{
    //   ButtonToOriginal();
    //  }
    if (firstPush === false) {
      setFirstPush(true);
    }
  }, [onPointerDown]);

  useEffect(() => {
    if (firstPush) {
      const action = actions[names[0]];
      action.clampWhenFinished = true;
      action.setLoop(THREE.LoopOnce, 1);

      if (
        !snapStateInAreaRooms.inAreaRoomEntryRoom &&
        stateWallPush.activeThemeIndex == Id
      ) {
        ButtonToOriginal();

        action.paused = false;
        playDoorOpen();
        action.reset();
        action.time = action.getClip().duration;
        action.timeScale = -1;

        action.play();

        setDoorClosed(true);
      } else if (
        snapStateInAreaRooms.inAreaRoomEntryRoom &&
        stateWallPush.activeThemeIndex == Id
      ) {
        ButtonToHover();
        playDoorOpen();
        action.reset();
        action.timeScale = 1;
        action.play();
        setDoorClosed(false);
      }

    }
  }, [snapStateInAreaRooms.inAreaRoomEntryRoom]);

  const handlePointerDown = () => {
    setPause(false);

    if (onPointerDown) {
      onPointerDown();
    }
  };

  const handlePointerOver = () => {
    setHover(true);
    if (doorClosed) {
      ButtonToHover();
    }
  };

  const handlePointerOut = () => {
    setHover(false);
    if (doorClosed) {
      ButtonToOriginal();
    }
  };

  useEffect(() => {
if(!snapPlayerPosition.isTracking){
    document.body.style.cursor = hovered ? "pointer" : "auto";
  }
  }, [hovered]);

  
  return (
    <>
      <Clone
        scale={scale}
        position={position}
        rotation={rotationInRadians}
        object={pointerModel.scene}
        ref={ref}
       
      >
 <Box 
 args={[1,1,1]} 
 position={[-0.8, 24, -40]}  
 scale={[3,20,20]}
 visible={false}
 onClick={handlePointerDown}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}>

  
 </Box>
      <Clone
            ref={ButtonRef}
            object={memoizeddoorButton.scene}
            position={[-0.8, 13, -40]}
            scale={1.4}
            // onClick= {onPointerDown()}
            visible={snapStateInAreaRooms.inAreaRoomEntryRoom }
         
      
          ></Clone>
      </Clone>
    </>
  );
}
// useGLTF.preload("../models/EntryRoom/Pointer.glb");
