import "./HeaderStyle.css";
import { motion } from "framer-motion";
import { statePlayerSwitch } from "../store";
import { useEffect, useState } from "react";
import { statePlayerPositionS } from "../store";
import { stateOrbitLocation, stateInAreaRooms, stateDevice, stateInArea } from "../store";
import { useSnapshot } from "valtio";
import { stateSound } from "../store";
import TransparentWindow from "./TransparentWindow";
 import Sound from "../Utils/Sound";
 import * as THREE from "three";

export default function Header() {
  return <HeaderKon />;
}

function HeaderKon() {
  const [charClicked, setCharClicked] = useState(false);
  const [audioClicked, setAudioClicked] = useState(true);

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isFp, setIsFp] = useState(false);
  const snapOrbitLoaction = useSnapshot(stateOrbitLocation)
  const snapPlayerSwitch = useSnapshot(statePlayerSwitch)
  const snapInAreaRooms = useSnapshot(stateInAreaRooms)
  const snapStateDevice = useSnapshot(stateDevice)
  const snapStateInArea = useSnapshot(stateInArea)
  const snapStateSound = useSnapshot(stateSound)

  const {
    playClickSound,
  
    playMouseIn,
    playMouseOut
  } = Sound();

  function handleCharClick() {
    playClickSound()
    if (!statePlayerSwitch.charSwitchClicked && !stateOrbitLocation.lerpRuns ) {
      statePlayerSwitch.charSwitchClicked = true;
      setCharClicked(true);
    } else if(!stateOrbitLocation.lerpRuns) {
      statePlayerSwitch.charSwitchClicked = false;
      setCharClicked(false);
    }

   
  }

  useEffect(()=>{
   if(!stateOrbitLocation.lerpRuns && !snapPlayerSwitch.charSwitchClicked) {
      // statePlayerSwitch.charSwitchClicked = false;
      setCharClicked(false);
    }

  },[snapPlayerSwitch.charSwitchClicked])

  function handleFPClick() {
    setIsFp(!isFp);
    playClickSound()
    if(!isFp){

      statePlayerPositionS.fpPlayer=true
    }
    else{
      statePlayerPositionS.fpPlayer=false
    }
  }

  function handleAudioClick() {
    playClickSound()
    if(snapStateSound.backAudio){
    stateSound.backAudio = false;
    setAudioClicked(false)
  }else{
    stateSound.backAudio = true;
    setAudioClicked(true)
  }
   
  }
  function toggleHome() {
    statePlayerPositionS.playerPositionS = new THREE.Vector3(0, 0, -150)
    statePlayerPositionS.homeclicked = true

  }


  function toggleFullscreen() {
    playClickSound()
    if (!isFullscreen) {
      // Request fullscreen
      const elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    } else {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen); // Toggle fullscreen state
  }


  const listItemsSalzMuseem = ["Landkarte"];
  const listItemsKulturgeschichte = [
    "Arbeitswelt",
    "Heimat",
    "Glaube",
    "Macht",
    "Gewalt",
    "Zeitmaschine Bergmann",
  ];
  
  const listItemsSalztransport = [
    "Schifffahrt",
    "Solerohre",
    "Die Städte",
    "Transport 1850",
    "Die Traun",
    "Zeitmaschine Transport",
  ];
  
  const listItemsSalzzabbau = [
    "Entstehung des Salzes",
    "Prähistorie",
    "Mittelalter und Neuzeit",
    "Moderner Bergbau",
    "Tourismus",
    "Zeitmaschine Werkzeuge",
  ];
  
  const listItemsOekologie = [
    "Bergrutsch",
    "Bergsturz",
    "Almen",
    "Eiszeitmodelle",
    "Dachstein",
  ];
  
  const listItemsHolzarbeit = [
      "Holz im Bergbau",
      "Holz im Transportwesen",
      "Werkzeuge",
      "Holzarbeit",
      "Papier",
      "Zeitmaschine Holz"
    ];







  const [open, setOpen] = useState(false);
  const [listItems, setlistItems] = useState([]);
  const [isOpenList, setIsOpenList] = useState(false);

  useEffect(() => {}, []);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleMenuSalzzeitMuseen = () => {
    // do something
    setOpen(false);
    setlistItems(listItemsSalzMuseem);
    setIsOpenList(true);
    console.log(isOpenList);
  };

  const handleMenuKulturgeschichte = () => {
    // do something
    setOpen(false);
    setlistItems(listItemsKulturgeschichte);
    setIsOpenList(true);
  };

  const handleMenuSalztransport = () => {
    // do something
    setOpen(false);
    setlistItems(listItemsSalztransport);
    setIsOpenList(true);
  };

  const handleMenuDasSalz = () => {
    // do something
    setOpen(false);
    setlistItems(listItemsSalzzabbau);
    setIsOpenList(true);
  };

  const handleMenuDieÖkologie = () => {
    // do something
    setOpen(false);
    setlistItems(listItemsOekologie);
    setIsOpenList(true);
  };

  const handleMenuDieHolzarbeit = () => {
    // do something
    setOpen(false);
    setlistItems(listItemsHolzarbeit);
    setIsOpenList(true);
  };

  const handleOnMouseOut = () => {
    // do something
    setOpen(false);

  };


  return (
    <>
      <TransparentWindow
        listItems={listItems}
        setIsOpenList={setIsOpenList}
        isOpenList={isOpenList}
      ></TransparentWindow>
    <motion.div
      className="container"
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ type: "spring", duration: 1.8, delay: 2 }}
    >

      <header className="header">
        {/* Logo */}
        <div className="logoContainerHUDOver">
        <div className="logoContainerHUD">
          <img
            className="museumIconHUD"
            src="../UI/IconMsueumHeader.svg"
            alt="Museum Icon"
          />
          <div className="logoHUD">SALZZEIT</div>
          <div className="logoSentenceHUD">Salzkammergut Connected History</div>
          
         
      
          
   
        </div>
        <img
            className="badIschlIconHUD"
            src="../Logos/Badischl.png"
            alt="Museum Icon"
          />
        </div>
        {/* Sidemenu */}
        
        
<div className="sideMenuContainer"    onMouseLeave={handleOnMouseOut}>

{!snapOrbitLoaction.orbitClicked && <div className="DropdownContainer"
   
    >
        <button className="buttonDrop" onClick={handleOpen}>
          SCHNELLREISE
        </button>
        {open ? (
            <div >
          <ul className="menu" >
            <li className="menu-item">
              <button onClick={handleMenuSalzzeitMuseen}>
                Salzzeit Museen
              </button>
            </li>
            <li className="menu-item">
              <button onClick={handleMenuKulturgeschichte}>
                Die Kulturgeschichte
              </button>
            </li>
            <li className="menu-item">
              <button onClick={handleMenuSalztransport}>
                Der Salztransport
              </button>
            </li>
            <li className="menu-item">
              <button onClick={handleMenuDasSalz}>Das Salz</button>
            </li>

            <li className="menu-item">
              <button onClick={handleMenuDieÖkologie}>Die Ökologie</button>
            </li>
            <li className="menu-item">
              <button onClick={handleMenuDieHolzarbeit}>Die Holzarbeit</button>
            </li>
          </ul>
          </div>
        ) : null}
 
      </div>}


{!snapStateInArea.inArea && <div className={`circleHeader ${audioClicked ? "clicked" : ""}`}>
            <img
              className="headerButton"
              src="UI/AudioButton2_.png"
              alt="Audio"
              onClick={handleAudioClick}
            />
          </div>}

 
     {  !snapStateDevice.isIphone &&  <div
            className={`circleHeader ${isFullscreen ? "clicked" : ""}`}
            onClick={toggleFullscreen}
          >
            <img
              className="headerButton"
              src="UI/FullscreenIcon_.png"
              alt="FullScreen"
            />
          </div>}
        
      {  !snapOrbitLoaction.orbitClicked && !snapPlayerSwitch.charSwitchClicked &&  <div
            className={`circleHeader ${isFp ? "clicked" : ""}`}
            onClick={handleFPClick}
          >
            <img
              className="headerButton"
              src="UI/FPButton_.png"
              alt="FirstPerson"
            />
          </div>}

          { !snapOrbitLoaction.orbitClicked && snapInAreaRooms.inAreaRoomEntryRoom &&  <div
            className={`circleHeader ${charClicked ? "clicked" : ""}`}
            onClick={handleCharClick}
          >
            <img
              className="headerButton"
              src="UI/CharButton2_.png"
              alt="Character"
            />
          </div>}
          {  !snapOrbitLoaction.orbitClicked && !snapPlayerSwitch.charSwitchClicked &&  <div
            className={`circleHeader`}
            onClick={toggleHome}
          >
            <img
              className="headerButton"
              src="UI/HomeIcon_.png"
              alt="Home"
            />
          </div>}
        </div>
      </header>
    </motion.div>
    </>
  );
}
