
import { useProgress } from "@react-three/drei"

import "./CustomLoaderProgress.css";
import { stateWallPush } from "../store";
import { useState } from "react";
import { useEffect } from "react";
import { useSnapshot } from "valtio";
import { statePlayerPositionS } from "../store";



export default function CustomLoaderProgress({title, text}) {
    const { progress, loaded, total } = useProgress()
  // Check if loading is still in progress
  const [showLoader, setShowLoader] = useState(true);


const snapWallPush = useSnapshot(stateWallPush)

useEffect(()=>{
if(!showLoader){
  statePlayerPositionS.blockPlayerTravel = false
}else{
  statePlayerPositionS.blockPlayerTravel = true
}

},[showLoader])

  useEffect(() => {

    //  setShowLoader(true);
    statePlayerPositionS.blockPlayerTravel = true


    const timeoutId = setTimeout(() => {
      setShowLoader(loaded !== null && total !== null && loaded !== total);
     
  
    
      
    }, 200); // Adjust the delay as needed

    return () => clearTimeout(timeoutId);

  }, [loaded, total]);

  return showLoader ? (
    <div className="RoomLoaderBack">
      <div className="titel">{title}</div>
     
      <div className ="loader"></div>
      <div className="text">{text}</div>
      {/* <div className="textProgress">{progress} % loaded</div> */}
    </div>
  ) : null;


};


